import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ChangeModeInput from '~/components/input/ChangeModeInput';
import TextInput from '~/components/input/TextInput';
import { ORDER_HEADER_HEIGHT } from '~/utils/constants';
import Pttt from './Pttt';
import SelectApiInput from '~/components/input/SelectApiInput';
import FormCustomer from '~/components/form/customer/FormCustomer';
import { useSelector } from 'react-redux';
import { numeralCustom } from '~/utils/helpers';
import { useOrderContext } from '~/pages/order/OrderPage';
import Event from './events/Event';
import CheckboxInput from '~/components/input/CheckboxInput';

function Info() {
  const { currentHd } = useSelector((state) => state.order);
  const { handleUpdateCurrentHd, print, setPrint } = useOrderContext();
  const [customer, setCustomer] = useState();

  const handleKhachHangChange = (c) => {
    handleUpdateCurrentHd({
      ma_kh: c?.ma_kh || '',
      ten_kh: c?.ten_kh || '',
    });
    setCustomer(c);
  };

  const handleCkhdChange = (ck) => {
    handleUpdateCurrentHd({
      tien_ck_hd: ck?.tien_ck || 0,
      ty_le_ck_hd: ck?.ty_le_ck || 0,
      exfields: { ...(currentHd.exfields || {}), ty_le_ck_pttt: 0 },
      ht_thanh_toan: '',
    });
  };

  const handleTypeChange = (_, val) => {
    const tyLeCkPttt = currentHd?.exfields?.ty_le_ck_pttt || 0;
    let tyLeCkHd = (currentHd?.ty_le_ck_hd || 0) - tyLeCkPttt;
    if (tyLeCkHd < 0) {
      tyLeCkHd = 0;
    }
    handleUpdateCurrentHd({
      is_website: Boolean(+val),
      ht_thanh_toan: '',
      exfields: { ...(currentHd.exfields || {}), ty_le_ck_pttt: 0 },
      ty_le_ck_hd: tyLeCkHd,
    });
  };

  const handleHoaDonChange = (_, val) => {
    handleUpdateCurrentHd({ hoa_don: val });
  };

  useEffect(() => {
    setCustomer(
      currentHd?.ma_kh
        ? {
            ma_kh: currentHd?.ma_kh || '',
            ten_kh: currentHd?.ten_kh || '',
          }
        : null
    );
  }, [currentHd]);

  return (
    <Box
      className="hidden-scroll"
      sx={{
        height: `calc(100vh - ${ORDER_HEADER_HEIGHT} - 10px - 10px - 24px - 42px)`,
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '10px',
        paddingBottom: '5px',
      }}
    >
      <Stack gap={1} sx={{ padding: '20px 0 10px 0' }}>
        <FormControl>
          <RadioGroup
            row
            value={currentHd?.is_website ? 1 : 0}
            onChange={handleTypeChange}
          >
            <FormControlLabel
              value={0}
              control={<Radio />}
              label="Đơn trực tiếp"
            />
            <FormControlLabel
              value={1}
              control={<Radio />}
              label="Đơn website"
            />
          </RadioGroup>
        </FormControl>
        <TextInput
          readOnly
          labelWidth="50%"
          label="Tiền hàng:"
          count={1}
          labelSx={{ fontWeight: '400', fontSize: '14px' }}
          textFieldSx={{
            '& .MuiInputBase-input': {
              fontSize: '14px',
              textAlign: 'right',
              padding: '0px',
            },
          }}
          value={numeralCustom(currentHd?.t_tien || 0).format()}
          onChange={() => {}}
        />
        <TextInput
          readOnly
          labelWidth="50%"
          label="Chiết khẩu sản phẩm:"
          labelSx={{ fontWeight: '400', fontSize: '14px' }}
          // count={numberOfCk || ''}
          count={2}
          textFieldSx={{
            '& .MuiInputBase-input': {
              fontSize: '14px',
              textAlign: 'right',
              padding: '0px',
            },
          }}
          value={numeralCustom(
            (currentHd?.tc_ck || 0) - (currentHd?.tien_ck_hd || 0)
          ).format()}
          onChange={() => {}}
        />
        <ChangeModeInput
          labelWidth="50%"
          disabled={currentHd?.exfields?.dmchietkhau?.length > 0}
          label="Chiết khấu hóa đơn:"
          count={3}
          labelSx={{ fontWeight: '400', fontSize: '14px' }}
          textFieldSx={{
            '& .MuiInputBase-input': {
              fontSize: '14px',
              textAlign: 'right',
              padding: '0px',
            },
          }}
          tienGoc={currentHd?.t_tien || 0}
          defaultTyLeCK={currentHd?.ty_le_ck_hd || 0}
          defaultTienCk={currentHd?.tien_ck_hd || 0}
          onChietKhauChange={handleCkhdChange}
        />
        <Stack gap={1}>
          {currentHd?.exfields?.ty_le_ck_pttt > 0 && (
            <Typography sx={{ fontStyle: 'italic', color: 'info.main' }}>
              (Chiết khấu bao gồm {currentHd?.exfields?.ty_le_ck_pttt}% hình
              thức thanh toán)
            </Typography>
          )}
        </Stack>
        {/* <TextInput
          readOnly
          count={4}
          labelWidth="50%"
          label="Tiền thuế:"
          labelSx={{ fontWeight: '400', fontSize: '14px' }}
          textFieldSx={{
            '& .MuiInputBase-input': {
              fontSize: '14px',
              textAlign: 'right',
              padding: '0px',
            },
          }}
          value={numeralCustom(currentHd?.t_thue || 0).format()}
          onChange={() => {}}
        /> */}
        <SelectApiInput
          placeholder="Chọn khách hàng"
          apiCode="customer"
          label="Khách hàng"
          labelWidth="50%"
          searchFileds={['ma_kh', 'ten_kh']}
          condition={{ kh_yn: true, ncc_yn: { $ne: true } }}
          getOptionLabel={(option) => option.ten_kh}
          selectedValue={customer}
          value={customer || { ma_kh: '', ten_kh: '' }}
          onSelect={handleKhachHangChange}
          FormAdd={FormCustomer}
        />
        <TextInput
          labelWidth="50%"
          label="Khách cần trả: 1-2-3"
          readOnly
          labelSx={{ fontWeight: '600', fontSize: '14px' }}
          textFieldSx={{
            '& .MuiInputBase-input': {
              fontSize: '14px',
              textAlign: 'right',
              padding: '0px',
            },
          }}
          value={numeralCustom(currentHd?.t_tt).format()}
          onChange={() => {}}
        />
        <Stack>
          <CheckboxInput
            label="Khách yêu cầu xuất hóa đơn"
            checked={!!currentHd?.hoa_don}
            onChange={handleHoaDonChange}
          />
          <CheckboxInput
            label="Hoàn tất và in"
            checked={!!print}
            onChange={() => {
              setPrint(!print);
            }}
          />
        </Stack>
        {/* <TextInput
          labelWidth="50%"
          label="Khách thanh toán:"
          labelSx={{ fontWeight: '600', fontSize: '14px' }}
          InputProps={{
            endAdornment: <Currentcy />,
          }}
          textFieldSx={{
            '& .MuiInputBase-input': {
              fontSize: '14px',
              textAlign: 'right',
              padding: '0px',
            },
          }}
          value={''}
          onChange={() => {}}
        /> */}
      </Stack>
      <Event />
      <Pttt />
    </Box>
  );
}

export default Info;
