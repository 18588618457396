import React, { useState, useEffect } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import MenuBase from '~/components/menu/MenuBase';
import { numeralCustom } from '~/utils/helpers';
import ChangeMode from './ChangeMode';
import ButtonBase from '~/components/button/ButtonBase';
import InputPrice from './InputPrice';

function Price({ detail, onChietKhauChange }) {
  const [anchor, setAnchor] = useState();
  const [isPercent, setIsPercent] = useState(false);
  const [giaTriChietKhau, setGiaTriChietKhau] = useState({
    ty_le_ck: detail?.ty_le_ck || 0,
    tien_ck: (detail?.tien_ck_nt || 0) / (detail?.sl_xuat || 1),
  });
  const handleClose = () => {
    setAnchor(null);
  };

  const handleSaveCk = async () => {
    onChietKhauChange(giaTriChietKhau);
    setAnchor(null);
  };

  const handleGiaTriChietKhauChange = (e) => {
    let value = isPercent
      ? e.target.value
      : numeralCustom(e.target.value).value();
    const giaGoc = detail.gia_ban_le;
    if (isPercent) {
      if (value > 100) {
        value = 100;
      }
      const tienCk = +((giaGoc * value) / 100).toFixed(0);
      setGiaTriChietKhau({ ty_le_ck: value, tien_ck: tienCk });
    } else {
      if (value > giaGoc) {
        value = giaGoc;
      }
      const tyLeCk = (value * 100) / giaGoc;
      setGiaTriChietKhau({ ty_le_ck: tyLeCk, tien_ck: value });
    }
  };

  useEffect(() => {
    setGiaTriChietKhau({
      ty_le_ck: detail.ty_le_ck || 0,
      tien_ck: detail.tien_ck_nt / detail.sl_xuat || 0,
    });
  }, [detail]);

  return (
    <>
      {!!anchor && (
        <MenuBase anchorEl={anchor} open={!!anchor} handleClose={handleClose}>
          <Stack
            sx={{ minWidth: '240px', width: 'auto', padding: '10px' }}
            spacing="5px"
          >
            {!!detail.thue_suat && (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography sx={{ fontSize: '14px' }}>
                  Giá trước VAT:
                </Typography>
                <InputPrice
                  width="50%"
                  readOnly
                  textAlign="right"
                  value={numeralCustom(detail.gia_ban_le).format()}
                />
              </Stack>
            )}
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography sx={{ fontSize: '14px' }}>
                Giá bán{detail.thue_suat ? `( VAT ${detail.thue_suat}%)` : ''}:
              </Typography>
              <InputPrice
                width="50%"
                readOnly
                textAlign="right"
                value={numeralCustom(detail.gia_ban_nt).format()}
              />
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography sx={{ fontSize: '14px' }}>Chiết khấu:</Typography>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                sx={{ width: '50%' }}
                spacing="5px"
              >
                {isPercent ? (
                  <InputPrice
                    type="number"
                    width="75px"
                    value={giaTriChietKhau.ty_le_ck}
                    onChange={handleGiaTriChietKhauChange}
                    textAlign="right"
                  />
                ) : (
                  <InputPrice
                    width="75px"
                    value={numeralCustom(giaTriChietKhau.tien_ck).format()}
                    onChange={handleGiaTriChietKhauChange}
                    textAlign="right"
                  />
                )}
                <ChangeMode
                  onChange={({ isPercent }) => setIsPercent(isPercent)}
                />
              </Stack>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography sx={{ fontSize: '14px' }}>Đơn giá:</Typography>
              <InputPrice
                width="50%"
                readOnly
                textAlign="right"
                style={{ fontWeight: 500 }}
                value={numeralCustom(
                  detail?.gia_ban_nt -
                    (detail.tien_ck_nt || 0) / (detail.sl_xuat || 1)
                ).format()}
              />
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              spacing="5px"
              justifyContent="center"
              sx={{ paddingTop: '5px' }}
            >
              <ButtonBase
                onClick={handleClose}
                variant="contained"
                sx={{
                  backgroundColor: 'error.main',
                  color: 'whitish.pureWhite',
                  '&:hover': {
                    backgroundColor: 'error.main',
                  },
                }}
              >
                Hủy
              </ButtonBase>
              <ButtonBase onClick={handleSaveCk} variant="contained">
                Lưu
              </ButtonBase>
            </Stack>
          </Stack>
        </MenuBase>
      )}
      <Box onClick={(e) => setAnchor(e.currentTarget)}>
        <InputPrice
          value={numeralCustom(
            (detail?.gia_ban_nt || 0) -
              (detail.tien_ck_nt || 0) / (detail.sl_xuat || 0)
          ).format()}
          width="70px"
          readOnly
        />
      </Box>
    </>
  );
}

export default Price;
