import React, { useState, useEffect } from 'react';
import { Box, Stack } from '@mui/material';
import FilterRadios from '../FilterRadios';
import moment from 'moment';
import FilterTimeFromTo from '../FilterTimeFromTo';
import FilterSelectApi from '../FilterSelectApi';
import { groupBy, numeralCustom } from '~/utils/helpers';
import { LABEL_KHO } from '~/utils/label.constant';
import ReportSCTVTModal from '~/components/modal/report/ReportSCTVTModal';

const concerns = [
  // bán hàng
  {
    value: 'banhang',
    label: 'Bán hàng',
    api: 'ctbanle',
    convertData: (data) => {
      let result = [];
      const total = data.pop();
      const dataGroup = groupBy({
        data,
        callbackMatch: (item) => `${item.ma_vt}_${item.ma_dvt}`,
      });

      dataGroup.forEach((group) => {
        const res = group.reduce(
          (acc, item) => {
            return {
              ma_vt: item.ma_vt,
              ten_vt: item.ten_vt,
              ma_dvt: item.ma_dvt,
              sl_ban: acc.sl_ban + item.sl_xuat,
              doanh_thu: acc.doanh_thu + item.tien_xuat,
            };
          },
          { ma_vt: '', ten_vt: '', ma_dvt: '', sl_ban: 0, doanh_thu: 0 }
        );
        result.push(res);
      });
      result = result.filter((item) => item.sl_ban);
      result.push({
        ma_vt: 'Tổng cộng',
        ten_vt: 'Tất cả hàng hóa',
        ma_dvt: '--',
        sl_ban: total.sl_xuat,
        doanh_thu: total.tien_xuat,
      });
      return result;
    },
    columns: [
      {
        name: 'Mã hàng',
        selector: (row) => row.ma_vt,
        width: '140px',
        wrap: true,
      },
      {
        name: 'Tên hàng',
        selector: (row) => row.ten_vt,
        wrap: true,
      },
      {
        name: 'Số lượng bán',
        selector: (row) => row.sl_ban,
        center: true,
        wrap: true,
      },
      {
        name: 'Đơn vị tính',
        selector: (row) => row.ma_dvt,
        center: true,
        wrap: true,
      },
      {
        name: 'Doanh thu',
        selector: (row) => row.doanh_thu,
        format: (row) => numeralCustom(row.doanh_thu).format(),
        right: true,
        wrap: true,
      },
    ],
  },
  // nhập hàng
  {
    value: 'nhaphang',
    label: 'Nhập hàng',
    api: 'ctbanle',
    convertData: (data) => {
      let result = [];
      const total = data.pop();
      const dataGroup = groupBy({
        data,
        callbackMatch: (item) => `${item.ma_vt}_${item.ma_dvt}`,
      });

      dataGroup.forEach((group) => {
        const res = group.reduce(
          (acc, item) => {
            return {
              ma_vt: item.ma_vt,
              ten_vt: item.ten_vt,
              ma_dvt: item.ma_dvt,
              sl_nhap: acc.sl_nhap + item.sl_nhap,
              tien_nhap: acc.tien_nhap + item.tien_nhap,
            };
          },
          { ma_vt: '', ten_vt: '', ma_dvt: '', sl_nhap: 0, tien_nhap: 0 }
        );
        result.push(res);
      });
      result = result.filter((item) => item.sl_nhap);
      result.push({
        ma_vt: 'Tổng cộng',
        ten_vt: 'Tất cả hàng hóa',
        ma_dvt: '--',
        sl_nhap: total.sl_nhap,
        tien_nhap: total.tien_nhap,
      });
      return result;
    },
    columns: [
      {
        name: 'Mã hàng',
        selector: (row) => row.ma_vt,
        width: '140px',
        wrap: true,
      },
      {
        name: 'Tên hàng',
        selector: (row) => row.ten_vt,
        wrap: true,
      },
      {
        name: 'Số lượng nhập',
        selector: (row) => row.sl_nhap,
        center: true,
        wrap: true,
      },
      {
        name: 'Đơn vị tính',
        selector: (row) => row.ma_dvt,
        center: true,
        wrap: true,
      },
      {
        name: 'Tiền nhập',
        selector: (row) => row.tien_nhap,
        format: (row) => numeralCustom(row.tien_nhap).format(),
        right: true,
        wrap: true,
      },
    ],
  },
  // Lợi nhuận
  {
    value: 'loinhuan',
    label: 'Lợi nhuận',
    api: 'ctbanle',
    convertData: (data) => {
      let result = [];
      const total = data.pop();
      let totalBenefit = 0;
      const dataGroup = groupBy({
        data,
        callbackMatch: (item) => item.ma_vt,
      });

      dataGroup.forEach((group) => {
        const res = group.reduce(
          (acc, item) => {
            return {
              ma_vt: item.ma_vt,
              ten_vt: item.ten_vt,
              tien_hang: acc.tien_hang + item.tien_hang,
              tien_ck: acc.tien_ck + item.tien_ck * item.sl_xuat,
              doanh_thu: acc.doanh_thu + item.tien_xuat,
              loi_nhuan:
                acc.loi_nhuan +
                (item.tien_xuat -
                  item.gia_von_nt * item.sl_xuat -
                  item.tien_ck -
                  item.tien_phi_nt),
            };
          },
          {
            ma_vt: '',
            ten_vt: '',
            tien_hang: 0,
            tien_ck: 0,
            doanh_thu: 0,
            loi_nhuan: 0,
          }
        );
        totalBenefit += res.loi_nhuan;
        result.push(res);
      });
      result.push({
        ma_vt: '',
        ten_vt: 'Tất cả hàng hóa',
        tien_hang: total.tien_hang,
        tien_ck: total.tien_ck,
        doanh_thu: total.tien_xuat,
        loi_nhuan: totalBenefit,
      });
      return result;
    },
    columns: [
      {
        name: 'Mã hàng',
        selector: (row) => row.ma_vt,
        width: '100px',
        wrap: true,
      },
      {
        name: 'Tên hàng',
        selector: (row) => row.ten_vt,
        width: '200px',
        wrap: true,
      },
      {
        name: 'Tiền hàng',
        selector: (row) => row.tien_hang,
        format: (row) => numeralCustom(row.tien_hang).format(),
        wrap: true,
      },
      {
        name: 'Tiền CK',
        selector: (row) => row.tien_ck,
        format: (row) => numeralCustom(row.tien_ck).format(),
        wrap: true,
      },
      {
        name: 'Doanh thu',
        selector: (row) => row.doanh_thu,
        format: (row) => numeralCustom(row.doanh_thu).format(),
        wrap: true,
      },
      {
        name: 'Lợi nhuận',
        selector: (row) => row.loi_nhuan,
        format: (row) => numeralCustom(row.loi_nhuan).format(),
        wrap: true,
      },
    ],
  },
  // nhập xuất tồn
  {
    value: 'thnxt',
    label: 'Nhập xuất tồn',
    labelKey: 'ten_kho',
    api: 'thnxt',
    modal: ReportSCTVTModal,
    convertData: (data) => {
      const total = data.pop();
      data.push({ ...total, ma_vt: 'Tổng cộng', ten_vt: 'Tất cả hàng hóa' });
      return data || [];
    },
    columns: [
      {
        name: 'Mã hàng hóa',
        selector: (row) => row.ma_vt,
        bold: true,

        wrap: true,
      },
      {
        name: 'Tên hàng hóa',
        selector: (row) => row.ten_vt,
        minWidth: '200px',
        wrap: true,
      },
      {
        name: 'Đơn vị tính',
        selector: (row) => row.ma_dvt,
        width: '100px',
        wrap: true,
      },
      {
        name: 'Tồn đầu kỳ',
        selector: (row) => row.ton_dau,
        center: true,
        wrap: true,
      },
      {
        name: 'Giá trị đầu kỳ',
        selector: (row) => row.du_dau,
        format: (row) => numeralCustom(row.du_dau).format(),
        wrap: true,
        center: true,
      },
      {
        name: 'Nhập kho',
        selector: (row) => row.sl_nhap,
        wrap: true,
        center: true,
      },
      {
        name: 'Giá trị nhập',
        selector: (row) => row.tien_nhap,
        format: (row) => numeralCustom(row.tien_nhap).format(),
        wrap: true,
        center: true,
      },
      {
        name: 'Xuất kho',
        selector: (row) => row.sl_xuat,
        width: '130px',
        center: true,
      },
      {
        name: 'Giá trị xuất',
        selector: (row) => row.tien_xuat,
        format: (row) => numeralCustom(row.tien_xuat).format(),
        wrap: true,
        center: true,
      },
      {
        name: 'Tồn cuối kỳ',
        selector: (row) => row.ton_cuoi,
        center: true,
        wrap: true,
      },
      {
        name: 'Giá trị cuối kỳ',
        selector: (row) => row.du_cuoi,
        format: (row) => numeralCustom(row.du_cuoi).format(),
        wrap: true,
      },
    ],
  },
];

function FilterBaoCaoHangHoa({
  setQueryObject = () => {},
  setConcern = () => {},
}) {
  const [valueConcern, setValueConcern] = useState(concerns[0].value);
  const [filter, setFilter] = useState({
    timeFrom: moment().startOf('months').format('YYYY-MM-DD'),
    timeTo: moment().format('YYYY-MM-DD'),
    kho: null,
    vat_tu: null,
  });

  useEffect(() => {
    setConcern(concerns.find((item) => item.value === valueConcern));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueConcern]);

  useEffect(() => {
    const queryObject = {};
    if (filter.timeFrom) {
      queryObject.tu_ngay = filter.timeFrom;
    }
    if (filter.timeTo) {
      queryObject.den_ngay = filter.timeTo;
    }
    if (filter.kho) {
      queryObject.ma_kho = filter.kho.ma_kho;
    }
    if (filter.vat_tu) {
      queryObject.ma_vt = filter.vat_tu.ma_vt;
    }
    setQueryObject(queryObject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <Box
      sx={{
        width: '100%',
        height: 'calc(100vh - 50px - 42px - 30px)',
        overflow: 'auto',
        p: 0.5,
      }}
      className="hidden-scroll"
    >
      <Stack gap={1}>
        <FilterRadios
          title="Chọn báo cáo"
          values={concerns}
          defaultValue={valueConcern}
          onChange={(newConcern) => setValueConcern(newConcern)}
        />
        <FilterSelectApi
          title={LABEL_KHO}
          apiCode="dmkho"
          placeholder={`Chọn ${LABEL_KHO}`}
          selectedValue={filter.kho || null}
          value={
            filter.kho
              ? { ma_kho: filter.kho.ma_kho, ten_kho: filter.kho.ten_kho }
              : null
          }
          searchFileds={['ma_kho', 'ten_kho']}
          getOptionLabel={(option) => option.ten_kho}
          onSelect={(value) => setFilter({ ...filter, kho: value })}
        />
        <FilterSelectApi
          title="Hàng hóa"
          placeholder="Chọn hàng hóa"
          apiCode="dmvt"
          selectedValue={filter.vat_tu || null}
          value={
            filter.vat_tu
              ? { ma_vt: filter.vat_tu.ma_vt, ten_vt: filter.vat_tu.ten_vt }
              : null
          }
          searchFileds={['ma_vt', 'ten_vt']}
          getOptionLabel={(option) => option.ten_vt}
          onSelect={(value) => setFilter({ ...filter, vat_tu: value })}
        />
        <FilterTimeFromTo
          defaultTimeFrom={filter.timeFrom}
          defaultTimeTo={filter.timeTo}
          title="Thời gian"
          onSearch={(time) => setFilter({ ...filter, ...time })}
        />
      </Stack>
    </Box>
  );
}

export default FilterBaoCaoHangHoa;
