import React from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import CollapseSection from '~/components/collapse/CollapseSection';
import { Controller } from 'react-hook-form';
import SelectApiInput from '~/components/input/SelectApiInput';
import FormNVT from '../../productGroup/FormNVT';
import FormXuatXu from '../../xuatxu/FormXuatXu';
import TextInput from '~/components/input/TextInput';
import { numeralCustom } from '~/utils/helpers';
import FormNHH from '../../dmnhh/FormNHH';

function InfoSpecifySection({
  loaiVatTu,
  show,
  setCollapses,
  control,
  register,
  isEdit,
}) {
  return (
    <CollapseSection
      title={`Thông tin ${loaiVatTu?.ten_lvt}`}
      show={show}
      onCollapse={() => setCollapses((prev) => ({ ...prev, info: !prev.info }))}
    >
      {/* Vật tư là hàng hóa thường */}
      {loaiVatTu.ma_lvt === 'HH' && (
        <Grid container spacing="20px">
          <Grid item xs={12} md={7}>
            <Stack spacing="20px" sx={{ width: '100%' }}>
              <Controller
                control={control}
                name="nhom_vat_tu"
                render={({ field: { onChange, value } }) => (
                  <SelectApiInput
                    label="Nhóm"
                    apiCode="dmnvt"
                    placeholder="Nhóm"
                    searchFileds={['ten_nvt']}
                    getOptionLabel={(option) => option.ten_nvt}
                    selectedValue={value}
                    value={value || { ma_nvt: '', ten_nvt: '' }}
                    onSelect={onChange}
                    FormAdd={FormNVT}
                  />
                )}
              />
              <Controller
                control={control}
                name="xuat_xu"
                render={({ field: { onChange, value } }) => (
                  <SelectApiInput
                    label="Xuất xứ"
                    apiCode="dmxuatxu"
                    placeholder="Xuất xứ sản phẩm"
                    searchFileds={['ma_xuat_xu', 'ten_xuat_xu']}
                    getOptionLabel={(option) => option.ten_xuat_xu}
                    selectedValue={value}
                    value={value || { ma_xuat_xu: '', ten_xuat_xu: '' }}
                    onSelect={onChange}
                    FormAdd={FormXuatXu}
                  />
                )}
              />
              <TextInput
                label="Vị trí"
                placeholder="Chỗ đặt trong kho"
                name="vi_tri"
                register={register}
              />
              <TextInput
                label="Quy cách"
                placeholder="VD: Hộp x 10 cái"
                name="quy_cach"
                register={register}
              />
              {/* <Controller
                control={control}
                name="ty_le_ck"
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    label="Hoa hồng NV"
                    placeholder="VD: 10"
                    value={numeralCustom(value).format()}
                    onChange={(e) => {
                      const number = e.target.value;
                      onChange(numeralCustom(number).value());
                    }}
                    InputProps={{
                      endAdornment: (
                        <Typography
                          sx={{
                            fontSize: '12px',
                            color: 'neutral.iconColor',
                          }}
                        >
                          (%)
                        </Typography>
                      ),
                    }}
                  />
                )}
              /> */}
            </Stack>
          </Grid>
          <Grid item xs={12} md={5}>
            <Stack spacing="20px">
              <Controller
                control={control}
                name="nhom_hoa_hong"
                render={({ field: { onChange, value } }) => (
                  <SelectApiInput
                    label="Nhóm hoa hồng"
                    labelWidth="40%"
                    apiCode="dmnhh"
                    placeholder="Chọn nhóm hoa hồng"
                    searchFileds={['ten']}
                    getOptionLabel={(option) => option.ten}
                    selectedValue={value}
                    value={value || { _id: '', ten: '' }}
                    onSelect={onChange}
                    FormAdd={FormNHH}
                  />
                )}
              />
              <Controller
                control={control}
                name="gia_mua"
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    labelWidth="40%"
                    label="Giá vốn"
                    placeholder="Giá vốn trung bình"
                    value={numeralCustom(value).format()}
                    onChange={(e) => {
                      const number = e.target.value;
                      onChange(numeralCustom(number).value());
                    }}
                    InputProps={{
                      endAdornment: (
                        <Typography
                          sx={{
                            fontSize: '12px',
                            color: 'neutral.iconColor',
                          }}
                        >
                          VND
                        </Typography>
                      ),
                    }}
                  />
                )}
              />
              <Controller
                control={control}
                name="gia_ban_le"
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    labelWidth="40%"
                    label="Giá gốc"
                    placeholder="Giá bán 1 đơn vị hàng hóa"
                    value={numeralCustom(value).format()}
                    onChange={(e) => {
                      const number = e.target.value;
                      onChange(numeralCustom(number).value());
                    }}
                    InputProps={{
                      endAdornment: (
                        <Typography
                          sx={{
                            fontSize: '12px',
                            color: 'neutral.iconColor',
                          }}
                        >
                          VND
                        </Typography>
                      ),
                    }}
                  />
                )}
              />
              <Controller
                control={control}
                name="trong_luong"
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    labelWidth="40%"
                    label="Trọng lượng"
                    placeholder="VD: 100"
                    value={numeralCustom(value).format()}
                    onChange={(e) => {
                      const number = e.target.value;
                      onChange(numeralCustom(number).value());
                    }}
                    InputProps={{
                      endAdornment: (
                        <Typography
                          sx={{
                            fontSize: '12px',
                            color: 'neutral.iconColor',
                          }}
                        >
                          Gram
                        </Typography>
                      ),
                    }}
                  />
                )}
              />
              {/* <Controller
                control={control}
                name="ma_lo_yn"
                render={({ field: { onChange, value } }) => (
                  <CheckboxInput
                    label="Theo dõi lô"
                    name="ma_lo_yn"
                    checked={value}
                    onChange={onChange}
                  />
                )}
              /> */}
            </Stack>
          </Grid>
        </Grid>
      )}
      {/* Vật tư là dược phẩm */}
      {loaiVatTu.ma_lvt === 'DP' && (
        <Grid container spacing="20px">
          <Grid item xs={12} md={7}>
            <Stack spacing="20px" sx={{ width: '100%' }}>
              <TextInput
                label="Số đăng ký"
                placeholder="Số đăng ký"
                name="so_dang_ky"
                register={register}
              />
              <Controller
                control={control}
                name="nhom_vat_tu"
                render={({ field: { onChange, value } }) => (
                  <SelectApiInput
                    label="Nhóm"
                    apiCode="dmnvt"
                    placeholder="Nhóm"
                    searchFileds={['ten_nvt']}
                    getOptionLabel={(option) => option.ten_nvt}
                    selectedValue={value}
                    value={value || { ma_nvt: '', ten_nvt: '' }}
                    onSelect={onChange}
                    FormAdd={FormNVT}
                  />
                )}
              />
              <Controller
                control={control}
                name="duong_dung"
                render={({ field: { onChange, value } }) => (
                  <SelectApiInput
                    label="Đường dùng"
                    apiCode="dmnvt"
                    placeholder="Đường dùng"
                    searchFileds={['ten_nvt']}
                    getOptionLabel={(option) => option.ten_nvt}
                    selectedValue={value}
                    value={value || { ma_nvt: '', ten_nvt: '' }}
                    onSelect={onChange}
                    FormAdd={FormNVT}
                  />
                )}
              />
              <TextInput
                label="Hoạt chất"
                placeholder="Hoạt chất"
                name="hoat_chat"
                register={register}
              />
              <TextInput
                label="Hàm lượng"
                placeholder="Hàm lượng"
                name="ham_luong"
                register={register}
              />
              <Controller
                control={control}
                name="xuat_xu"
                render={({ field: { onChange, value } }) => (
                  <SelectApiInput
                    label="Xuất xứ"
                    apiCode="dmxuatxu"
                    placeholder="Xuất xứ sản phẩm"
                    searchFileds={['ma_xuat_xu', 'ten_xuat_xu']}
                    getOptionLabel={(option) => option.ten_xuat_xu}
                    selectedValue={value}
                    value={value || { ma_xuat_xu: '', ten_xuat_xu: '' }}
                    onSelect={onChange}
                    FormAdd={FormXuatXu}
                  />
                )}
              />
              <TextInput
                label="Quy cách"
                placeholder="VD: Hộp x 10 cái"
                name="quy_cach"
                register={register}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={5}>
            <Stack spacing="20px">
              <Controller
                control={control}
                name="nhom_hoa_hong"
                render={({ field: { onChange, value } }) => (
                  <SelectApiInput
                    label="Nhóm hoa hồng"
                    labelWidth="40%"
                    apiCode="dmnhh"
                    placeholder="Chọn nhóm hoa hồng"
                    searchFileds={['ten']}
                    getOptionLabel={(option) => option.ten}
                    selectedValue={value}
                    value={value || { _id: '', ten: '' }}
                    onSelect={onChange}
                    FormAdd={FormNHH}
                  />
                )}
              />
              <Controller
                control={control}
                name="gia_mua"
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    labelWidth="40%"
                    label="Giá vốn"
                    placeholder="Giá vốn trung bình"
                    value={numeralCustom(value).format()}
                    onChange={(e) => {
                      const number = e.target.value;
                      onChange(numeralCustom(number).value());
                    }}
                    InputProps={{
                      endAdornment: (
                        <Typography
                          sx={{
                            fontSize: '12px',
                            color: 'neutral.iconColor',
                          }}
                        >
                          VND
                        </Typography>
                      ),
                    }}
                  />
                )}
              />
              <Controller
                control={control}
                name="gia_ban_le"
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    labelWidth="40%"
                    label="Giá gốc"
                    placeholder="Giá bán 1 đơn vị hàng hóa"
                    value={numeralCustom(value).format()}
                    onChange={(e) => {
                      const number = e.target.value;
                      onChange(numeralCustom(number).value());
                    }}
                    InputProps={{
                      endAdornment: (
                        <Typography
                          sx={{
                            fontSize: '12px',
                            color: 'neutral.iconColor',
                          }}
                        >
                          VND
                        </Typography>
                      ),
                    }}
                  />
                )}
              />
              <TextInput
                labelWidth="40%"
                label="Vị trí"
                placeholder="Chỗ đặt trong kho"
                name="vi_tri"
                register={register}
              />
              <Controller
                control={control}
                name="trong_luong"
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    labelWidth="40%"
                    label="Trọng lượng"
                    placeholder="VD: 100"
                    value={numeralCustom(value).format()}
                    onChange={(e) => {
                      const number = e.target.value;
                      onChange(numeralCustom(number).value());
                    }}
                    InputProps={{
                      endAdornment: (
                        <Typography
                          sx={{
                            fontSize: '12px',
                            color: 'neutral.iconColor',
                          }}
                        >
                          Gram
                        </Typography>
                      ),
                    }}
                  />
                )}
              />
              {/* <Controller
                control={control}
                name="ty_le_ck"
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    labelWidth="40%"
                    label="Hoa hồng NV"
                    placeholder="VD: 10"
                    value={numeralCustom(value).format()}
                    onChange={(e) => {
                      const number = e.target.value;
                      onChange(numeralCustom(number).value());
                    }}
                    InputProps={{
                      endAdornment: (
                        <Typography
                          sx={{
                            fontSize: '12px',
                            color: 'neutral.iconColor',
                          }}
                        >
                          (%)
                        </Typography>
                      ),
                    }}
                  />
                )}
              /> */}
              {/* <Controller
                control={control}
                name="ma_lo_yn"
                render={({ field: { onChange, value } }) => (
                  <CheckboxInput
                    label="Theo dõi lô"
                    name="ma_lo_yn"
                    checked={value}
                    onChange={onChange}
                  />
                )}
              /> */}
            </Stack>
          </Grid>
        </Grid>
      )}
      {/* Vật tư là nguyên vật liệu */}
      {loaiVatTu.ma_lvt === 'NVL' && (
        <Grid container spacing="20px">
          <Grid item xs={12} md={7}>
            <Stack spacing="20px" sx={{ width: '100%' }}>
              <Controller
                control={control}
                name="xuat_xu"
                render={({ field: { onChange, value } }) => (
                  <SelectApiInput
                    label="Xuất xứ"
                    apiCode="dmxuatxu"
                    placeholder="Xuất xứ sản phẩm"
                    searchFileds={['ma_xuat_xu', 'ten_xuat_xu']}
                    getOptionLabel={(option) => option.ten_xuat_xu}
                    selectedValue={value}
                    value={value || { ma_xuat_xu: '', ten_xuat_xu: '' }}
                    onSelect={onChange}
                    FormAdd={FormXuatXu}
                  />
                )}
              />
              <TextInput
                label="Vị trí"
                placeholder="Chỗ đặt trong kho"
                name="vi_tri"
                register={register}
              />
              <TextInput
                label="Quy cách"
                placeholder="VD: Hộp x 10 cái"
                name="quy_cach"
                register={register}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={5}>
            <Stack spacing="20px">
              <Controller
                control={control}
                name="gia_mua"
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    labelWidth="40%"
                    label="Giá vốn"
                    placeholder="Giá vốn trung bình"
                    value={numeralCustom(value).format()}
                    onChange={(e) => {
                      const number = e.target.value;
                      onChange(numeralCustom(number).value());
                    }}
                    InputProps={{
                      endAdornment: (
                        <Typography
                          sx={{
                            fontSize: '12px',
                            color: 'neutral.iconColor',
                          }}
                        >
                          VND
                        </Typography>
                      ),
                    }}
                  />
                )}
              />
              <Controller
                control={control}
                name="trong_luong"
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    labelWidth="40%"
                    label="Trọng lượng"
                    placeholder="VD: 100"
                    value={numeralCustom(value).format()}
                    onChange={(e) => {
                      const number = e.target.value;
                      onChange(numeralCustom(number).value());
                    }}
                    InputProps={{
                      endAdornment: (
                        <Typography
                          sx={{
                            fontSize: '12px',
                            color: 'neutral.iconColor',
                          }}
                        >
                          Gram
                        </Typography>
                      ),
                    }}
                  />
                )}
              />
            </Stack>
          </Grid>
        </Grid>
      )}
    </CollapseSection>
  );
}

export default InfoSpecifySection;
