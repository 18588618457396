import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Stack, Typography } from '@mui/material';
import TextInput from '../../input/TextInput';
import useApisContext from '~/hooks/hookContext/useApisContext';
import { generateLinkImage, postOrPutDataDanhMuc } from '~/utils/helpers';
import ModalForm from '~/components/modal/ModalForm';
import AreaInput from '~/components/input/AreaInput';
import ImageInput from '~/components/input/ImageInput';
import useToken from '~/hooks/useToken';

const defaultData = { stt: '', image_url: '', dien_giai: '' };

export default function FormBanner({
  open,
  defaultValues,
  isEdit = false,
  currentIndex = -1,
  rows = [],
  setCurrentIndex = () => {},
  handleClose = () => {},
  setLoad = () => {},
}) {
  const originalData = { ...defaultData, ...defaultValues };
  const { asyncPostData, asyncPutData, uploadFile } = useApisContext();
  const token = useToken();
  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
    register,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: originalData,
  });
  const [file, setFile] = useState();

  const handleReset = () => {
    reset(originalData);
  };

  const generateDataPost = async (values) => {
    // save picture
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      const resp = await uploadFile({ formData, folder: 'banners', token });
      if (resp) {
        values.image_url = resp.fileUrl;
      }
    }
    return values;
  };

  const handleSave = async (values, mode) => {
    const dataPost = await generateDataPost(values);
    await postOrPutDataDanhMuc({
      isEdit,
      asyncPostData,
      asyncPutData,
      apiCode: 'banners',
      data: dataPost,
      handleClose: mode === 1 ? null : handleClose,
      reset: mode === 1 ? (isEdit ? null : reset) : isEdit ? null : reset,
      dataReset: originalData,
      setLoad,
    });
  };
  useEffect(() => {
    reset(originalData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  return (
    <ModalForm
      open={open}
      rows={rows}
      width="500px"
      title="banner"
      isEdit={isEdit}
      isSubmitting={isSubmitting}
      currentIndex={currentIndex}
      handleSave={handleSave}
      handleClose={handleClose}
      handleReset={handleReset}
      handleSubmit={handleSubmit}
      setCurrentIndex={setCurrentIndex}
    >
      <Stack gap={2}>
        <TextInput
          name="stt"
          type="number"
          label="Số thứ tự"
          placeholder="Nhập số thứ tự"
          register={register}
        />
        <AreaInput
          label="Ghi chú"
          name="dien_giai"
          register={register}
          placeholder="Nhập ghi chú nhập kho"
        />
        <Stack direction="column" alignItems="center" gap={1}>
          <ImageInput
            wrapperSx={{ width: '400px', height: '200px' }}
            url={
              defaultValues?.image_url
                ? generateLinkImage(defaultValues?.image_url)
                : ''
            }
            onChange={setFile}
          />
          <Typography sx={{ fontStyle: 'italic' }}>
            Kích thước: 600px x 400px
          </Typography>
        </Stack>
      </Stack>
    </ModalForm>
  );
}
