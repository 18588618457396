import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Avatar,
  Box,
  Chip,
  Grid,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import { FiSave } from 'react-icons/fi';
import { v4 } from 'uuid';
import * as yup from 'yup';
import ButtonBase from '~/components/button/ButtonBase';
import SearchAndSelectInput from '~/components/input/SearchAndSelectInput';
import ModalBase from '~/components/modal/ModalBase';
import useAlertContext from '~/hooks/hookContext/useAlertContext';
import useApisContext from '~/hooks/hookContext/useApisContext';
import {
  formatDateDisplay,
  generateLinkImage,
  numeralCustom,
} from '~/utils/helpers';
import ProductImage from '~/assets/img/product.png';
import TextInput from '~/components/input/TextInput';
import SelectApiInput from '~/components/input/SelectApiInput';
import FormLo from '../../lo/FormLo';

const originSchema = {
  vat_tu: yup
    .object()
    .typeError('Vui lòng chọn hàng hóa')
    .required('Vui lòng chọn hàng hóa'),
};

function FormAddDetail({
  open,
  handleClose,
  isEdit,
  addDetail,
  defaultValues,
}) {
  const defaultData = {
    vat_tu: null,
    gia_von_nt: 0,
    sl_nhap: 0,
    tien_hang_nt: 0,
    tien_phi_nt: 0,
    tien_ck_nt: 0,
    ty_le_ck: 0,
    tien_nhap_nt: 0,
    lo: null,
  };
  const originData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        vat_tu: defaultValues.ma_vt
          ? {
              ma_vt: defaultValues.ma_vt,
              ten_vt: defaultValues.ten_vt,
              ma_dvt: defaultValues.ma_dvt,
              ten_dvt: defaultValues.ten_dvt,
              ma_lo_yn: !!defaultValues.ma_lo,
            }
          : null,
        lo: defaultValues.ma_lo ? { ma_lo: defaultValues.ma_lo } : null,
      }
    : defaultData;
  const [schema, setSchema] = useState(yup.object(originSchema));
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: defaultValues ? originData : defaultData,
  });
  const { asyncSearchList } = useApisContext();
  const showAlert = useAlertContext();

  const vatTu = watch('vat_tu');
  const soLuongNhap = watch('sl_nhap');
  const giaVon = watch('gia_von_nt');
  const tienNhap = watch('tien_nhap_nt');

  const handleSelectProduct = async (selectedProduct) => {
    setValue('vat_tu', selectedProduct);
  };

  const getLos = async () => {
    try {
      const data = await asyncSearchList({
        apiCode: 'dmlo',
        condition: {
          page: 1,
          limit: 999999,
          q: {
            status: true,
            ma_vt: vatTu?.ma_vt,
            han_sd: { $gt: moment().hours(0).minutes(0).seconds(0) },
          },
        },
      });
      if (data && Array.isArray(data) && data.length === 1) {
        setValue('lo', data[0]);
      } else {
        setValue('lo', null);
      }
    } catch (error) {
      showAlert(error?.message || error?.error || 'Something went wrong!');
    }
  };

  useEffect(() => {
    if (vatTu) {
      if (vatTu.ma_lo_yn) {
        if (!isEdit) {
          getLos();
        }
        setSchema(
          yup.object({
            ...originSchema,
            lo: yup
              .object()
              .typeError('Vui lòng chọn lô')
              .required('Vui lòng chọn lô'),
          })
        );
      } else {
        setSchema(yup.object(originSchema));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vatTu, isEdit]);

  const handleSave = (values) => {
    return new Promise((resovle) => {
      setTimeout(() => {
        addDetail(values, isEdit);
        handleClose();
        resovle();
      }, 200);
    });
  };

  return (
    <>
      <ModalBase
        open={open}
        handleClose={handleClose}
        width="500px"
        title={`${isEdit ? 'Chỉnh sửa' : 'Thêm'} hàng mua`}
        actions={[
          <ButtonBase
            key={v4()}
            onClick={handleSubmit(handleSave)}
            loading={isSubmitting}
            startIcon={<FiSave style={{ fontSize: '16px' }} />}
          >
            Lưu
          </ButtonBase>,
          <ButtonBase key={v4()} variant="outlined" onClick={handleClose}>
            Hủy
          </ButtonBase>,
        ]}
      >
        <Stack gap={1}>
          <Stack gap={1}>
            <Box
              sx={{
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: '10px',
                px: 1,
                py: 0.5,
              }}
            >
              <SearchAndSelectInput
                onSelect={handleSelectProduct}
                listBoxSx={{ maxHeight: '40vh' }}
              />
            </Box>
            {/* Selected product */}
            {vatTu ? (
              <Stack
                direction="row"
                alignItems="center"
                gap={1}
                sx={{
                  backgroundColor: 'primary.opacity',
                  px: 2,
                  py: 1,
                  borderRadius: '10px',
                }}
              >
                <Avatar
                  src={
                    vatTu?.picture_thumb
                      ? generateLinkImage(vatTu.picture_thumb)
                      : ProductImage
                  }
                  alt="product image"
                  sx={{ borderRadius: '4px' }}
                />
                <Typography sx={{ lineBreak: 'anywhere' }}>
                  {vatTu?.ten_vt}
                </Typography>
                <Chip
                  variant="filled"
                  label={vatTu?.ten_dvt}
                  color="primary"
                  sx={{
                    '& .MuiChip-label': {
                      color: 'common.white',
                    },
                  }}
                />
              </Stack>
            ) : (
              <Typography
                sx={{
                  color: !!errors?.vat_tu?.message ? 'error.main' : '',
                  textAlign: 'center',
                }}
              >
                {errors?.vat_tu?.message || 'Chọn hàng hóa cần nhập'}
              </Typography>
            )}
          </Stack>
          {!!vatTu && (
            <Box
              sx={{
                border: '1px dashed',
                borderColor: 'divider',
                p: 2,
                borderRadius: '10px',
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="gia_von_nt"
                    control={control}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <TextInput
                          value={numeralCustom(value).format()}
                          onChange={(e) => {
                            const val = numeralCustom(e.target.value).value();
                            onChange(val);
                            // tính tiền hàng
                            // tính tiền nhập
                            const newTienHang = (soLuongNhap || 0) * val;
                            setValue('tien_hang_nt', newTienHang);
                            setValue('tien_nhap_nt', newTienHang);
                          }}
                          label="Giá vốn"
                          placeholder="Nhập giá vốn mua hàng"
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="sl_nhap"
                    control={control}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <TextInput
                          label="Số lượng"
                          placeholder="Nhập số lượng nhập"
                          type="number"
                          value={value}
                          onChange={(e) => {
                            const val = e.target.value;
                            onChange(val);
                            // tinh tien hang
                            // tinh tien nhap
                            const newTienHang = (giaVon || 0) * val;
                            setValue('tien_hang_nt', newTienHang);
                            setValue('tien_nhap_nt', newTienHang);
                          }}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    label="Tiền nhập"
                    placeholder="Tiền nhâp hàng"
                    readOnly
                    value={numeralCustom(tienNhap).format()}
                    onChange={() => {}}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="lo"
                    render={({ field: { onChange, value } }) => (
                      <SelectApiInput
                        label="Lô / HSD"
                        apiCode="dmlo"
                        placeholder="Chọn lô hàng hóa (nếu có)"
                        searchFileds={['ma_lo']}
                        condition={{ status: true, ma_vt: vatTu?.ma_vt || '' }}
                        getOptionLabel={(option) => option.ma_lo}
                        renderOption={({ key, ...optionProps }, option) => {
                          return (
                            <MenuItem key={key} {...optionProps}>
                              {option.ma_lo} -{' '}
                              {formatDateDisplay(option.han_sd)}
                            </MenuItem>
                          );
                        }}
                        selectedValue={value}
                        value={value || { ma_lo: '' }}
                        onSelect={onChange}
                        FormAdd={FormLo}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </Stack>
      </ModalBase>
    </>
  );
}

export default FormAddDetail;
