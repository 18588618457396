import React from 'react';
import {
  Avatar,
  Box,
  Button,
  Chip,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import TextInput from '../input/TextInput';
import { generateLinkImage, numeralCustom } from '~/utils/helpers';
import DatetimeInput from '../input/DatetimeInput';
import moment from 'moment';
import useAlertContext from '~/hooks/hookContext/useAlertContext';
import useApisContext from '~/hooks/hookContext/useApisContext';
import useAuthorize from '~/hooks/useAuthorize';
import ProductImage from '~/assets/img/product.png';
import { FiLock, FiUnlock } from 'react-icons/fi';
import { LOAI_VAT_TU } from '~/utils/constants';
import { useSelector } from 'react-redux';
import useConfirmContext from '~/hooks/hookContext/useConfirmContext';

function ExpandProduct({ data, openForm, setLoad }) {
  const userData = useSelector((state) => state.auth.user);
  const allowAuthorize = useAuthorize();
  const showAlert = useAlertContext();
  const showConfirm = useConfirmContext();
  const { asyncDelete, asyncPutData } = useApisContext();

  const handleStopSell = async () => {
    try {
      const resp = await asyncPutData({
        apiCode: 'dmvt',
        uniqueValue: data?._id,
        data: { status: !data?.status },
      });
      if (!resp?.error) {
        setLoad((prev) => prev + 1);
      }
    } catch (error) {
      showAlert({
        type: 'error',
        message: error?.message || error?.error || 'Something went wrong',
      });
    }
  };

  const handleDelete = async () => {
    try {
      const resp = await asyncDelete({
        apiCode: 'dmvt',
        uniqueValue: data._id,
      });
      if (!resp?.error) {
        setLoad((prev) => prev + 1);
      }
    } catch (error) {
      showAlert({
        type: 'error',
        message: error?.message || 'Something went wrong',
      });
    }
  };

  return (
    <Box
      sx={{
        padding: '20px',
        backgroundColor: 'whitish.graySoft',
        border: '1px solid',
        borderColor: 'primary.main',
        borderRadius: '0 0 4px 4px',
        position: 'relative',
        zIndex: 0,
      }}
    >
      <Grid container spacing="20px">
        <Grid item xs={12} md={2}>
          <Stack gap={1}>
            <Box
              sx={{ width: '100%', paddingTop: '100%', position: 'relative' }}
            >
              <Avatar
                src={
                  data?.picture_thumb
                    ? generateLinkImage(data.picture_thumb)
                    : ProductImage
                }
                alt="product image"
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  borderRadius: '10px',
                }}
              />
              {data?.exfields?.hoa_don && (
                <Chip
                  label="Có hóa đơn"
                  variant="filled"
                  color="primary"
                  size="small"
                  sx={{
                    position: 'absolute',
                    bottom: '5px',
                    left: '5px',
                    zIndex: 10,
                    '& .MuiChip-label': {
                      color: 'common.white',
                    },
                  }}
                />
              )}
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} md={5}>
          <Stack spacing="20px">
            <TextInput
              readOnly
              label="Mã hàng"
              labelWidth="30%"
              value={data?.ma_vt}
            />
            <TextInput
              readOnly
              label="Tên hàng"
              labelWidth="30%"
              value={data?.ten_vt}
            />
            <TextInput
              readOnly
              label="Loại hàng"
              labelWidth="30%"
              value={
                LOAI_VAT_TU.find((item) => item.ma_lvt === data.ma_lvt)?.ten_lvt
              }
            />
            <TextInput
              readOnly
              label="Nhóm hàng"
              labelWidth="30%"
              value={data?.ten_nvt}
            />
            <TextInput
              readOnly
              label="Giá vốn"
              labelWidth="30%"
              value={numeralCustom(data?.gia_mua).format()}
            />
            <TextInput
              readOnly
              label="Giá bán"
              labelWidth="30%"
              value={numeralCustom(data?.gia_ban_le).format()}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={5}>
          <Stack spacing="20px">
            <DatetimeInput
              labelWidth="40%"
              label="Ngày tạo"
              readOnly
              value={moment(data?.date_created).format('YYYY-MM-DD HH:mm')}
            />
            <TextInput
              readOnly
              labelWidth="40%"
              label="Người tạo"
              value={data?.user_created}
            />
            <DatetimeInput
              labelWidth="40%"
              label="Ngày cập nhật cuối"
              readOnly
              value={moment(data?.date_updated).format('YYYY-MM-DD HH:mm')}
            />
            <TextInput
              readOnly
              labelWidth="40%"
              label="Người cập nhật cuối"
              value={data?.user_updated}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            gap={1}
          >
            {/* Ngừng kinh doanh */}
            {allowAuthorize({ type: 'update', module: 'dmvt' }) ||
            userData?.admin ? (
              <Button
                variant="contained"
                color="warning"
                onClick={() => {
                  showConfirm({
                    title: 'Xác nhận',
                    content: (
                      <Box sx={{ padding: '0 10px' }}>
                        <Typography
                          sx={{ fontSize: '14px', textAlign: 'center' }}
                        >
                          {data?.status
                            ? 'Bạn có chắc muốn ngừng kinh doanh sản phẩm này ?'
                            : 'Sản phẩm này sẽ được kinh doanh trở lại'}
                        </Typography>
                      </Box>
                    ),
                    onConfirm: handleStopSell,
                  });
                }}
                sx={{ color: 'common.white', textTransform: 'none' }}
                startIcon={
                  data.status === true ? (
                    <FiLock size={16} />
                  ) : (
                    <FiUnlock size={16} />
                  )
                }
              >
                {data.status === true
                  ? 'Ngừng kinh doanh'
                  : 'Cho phép kinh doanh'}
              </Button>
            ) : null}
            {/* Chỉnh sửa */}
            {allowAuthorize({ type: 'update', module: 'dmvt' }) ||
            userData?.admin ? (
              <Button
                variant="contained"
                color="secondary"
                onClick={openForm}
                sx={{ color: 'common.white', textTransform: 'none' }}
              >
                Chỉnh sửa
              </Button>
            ) : null}
            {/* Xóa */}
            {allowAuthorize({ type: 'delete', module: 'dmvt' }) ||
            userData?.admin ? (
              <Button
                variant="contained"
                color="error"
                sx={{ color: 'common.white', textTransform: 'none' }}
                onClick={() => {
                  showConfirm({
                    title: 'Xác nhận',
                    content: (
                      <Box sx={{ padding: '0 10px' }}>
                        <Typography
                          sx={{ fontSize: '14px', textAlign: 'center' }}
                        >
                          Bạn có chắc muốn xóa sản phẩm này không ?
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: '14px',
                            textAlign: 'center',
                            fontStyle: 'italic',
                            color: 'warning.main',
                          }}
                        >
                          Sản phẩm đã xóa sẽ không thể khôi phục
                        </Typography>
                      </Box>
                    ),
                    onConfirm: handleDelete,
                  });
                }}
              >
                Xóa
              </Button>
            ) : null}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ExpandProduct;
