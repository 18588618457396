import React, { useRef, useState } from 'react';
import ModalBase from './ModalBase';
import {
  Avatar,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';
import ButtonBase from '../button/ButtonBase';
import { BsUpload } from 'react-icons/bs';
import ExcelIcon from '~/assets/img/excel.png';
import useApisContext from '~/hooks/hookContext/useApisContext';
import useLinkImage from '~/hooks/useLinkImage';
import ModalExcelFiles from './ModalExcelFiles';
import { downloadFile } from '~/utils/helpers';
import { useMemo } from 'react';
import useAlertContext from '~/hooks/hookContext/useAlertContext';
import { MdClose } from 'react-icons/md';
import useBackdropContext from '~/hooks/hookContext/useBackdropContext';

function ModalImportExcel({ open, handleClose, apiCode = 'dmvt', setLoad }) {
  const showAlert = useAlertContext();
  const [, setBackdrop] = useBackdropContext();
  const { asyncPostData, callApi } = useApisContext();
  const generateLink = useLinkImage();
  const inputFileRef = useRef();
  const [fileExcel, setFileExcel] = useState();
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [excelFiles, setExcelFiles] = useState([]);

  const handleUpload = () => {
    inputFileRef.current.click();
  };

  const handleGetExcelFile = async () => {
    const resp = await callApi({
      method: 'get',
      endpoint: `/importexceltemplate?code=${apiCode}`,
    });
    if (resp && Array.isArray(resp) && resp.length > 0) {
      if (resp.length < 2) {
        const link = generateLink(resp[0].file);
        if (link) {
          downloadFile({ link, showAlert });
        }
      } else {
        setExcelFiles(resp);
      }
    } else {
      showAlert({ type: 'info', message: 'Không có file để tải về' });
    }
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFileExcel(file);
  };
  const handleImportFile = async () => {
    try {
      if (!fileExcel) {
        return;
      }
      setBackdrop(true);
      setLoading(true);
      const formData = new FormData();
      formData.append('xlsx', fileExcel, fileExcel?.name);
      const resp = await asyncPostData({
        apiCode,
        endpoint: `/import/excel?ma_ct=${apiCode.toUpperCase()}&update=${checked}`,
        data: formData,
        options: { headers: { 'Content-Type': 'multipart/form-data' } },
      });
      if (!resp?.error) {
        handleClose();
        setLoad((prev) => prev + 1);
      }
    } catch (error) {
      showAlert({
        type: 'error',
        message: 'Lỗi khi nhập file excel',
      });
    } finally {
      setBackdrop(false);
      setLoading(false);
    }
  };

  const openExcelFiles = useMemo(() => {
    return !!(excelFiles.length >= 2);
  }, [excelFiles.length]);

  return (
    <>
      {openExcelFiles && (
        <ModalExcelFiles
          open={openExcelFiles}
          handleClose={() => setExcelFiles([])}
          data={excelFiles}
        />
      )}
      <ModalBase
        width="500px"
        title="Nhập file excel"
        open={open}
        handleClose={handleClose}
        actions={[
          <ButtonBase
            key={1}
            onClick={handleImportFile}
            loading={loading}
            disabled={!fileExcel}
          >
            Nhập
          </ButtonBase>,
          <ButtonBase
            key={2}
            onClick={handleClose}
            startIcon={<MdClose style={{ fontSize: '16px' }} />}
            sx={{
              backgroundColor: 'error.main',
              '&:hover': { backgroundColor: 'error.main' },
            }}
          >
            Đóng
          </ButtonBase>,
        ]}
      >
        <Stack alignItems="center">
          <Stack alignItems="center">
            <input
              hidden
              type="file"
              name="excel"
              accept=".xlsx, .xls"
              ref={inputFileRef}
              onChange={handleFileChange}
            />
            <ButtonBase
              onClick={handleUpload}
              startIcon={<BsUpload size="14px" />}
            >
              Tải lên
            </ButtonBase>
            {fileExcel && (
              <Stack alignItems="center">
                <Avatar src={ExcelIcon} sx={{ width: 100, height: 100 }} />
                <Typography sx={{ fontSize: '12px', color: 'primary.main' }}>
                  {fileExcel.name}
                </Typography>
              </Stack>
            )}
          </Stack>
          <FormControlLabel
            label="Ghi đè nếu trùng mã"
            sx={{ '& .MuiFormControlLabel-label': { fontSize: '14px' } }}
            control={
              <Checkbox
                checked={checked}
                onChange={(e) => setChecked(e.target.checked)}
                size="small"
              />
            }
          />
          <Typography
            sx={{ fontSize: '14px', textAlign: 'center', marginTop: '10px' }}
          >
            Bạn chưa có file mẫu? Hãy{' '}
            <Typography
              onClick={handleGetExcelFile}
              component="span"
              sx={{
                width: '100%',
                fontSize: '14px',
                color: 'primary.main',
                cursor: 'pointer',
                '&:hover': { color: 'secondary.main' },
              }}
            >
              tải về
            </Typography>{' '}
            và điền thông tin theo file mẫu.
          </Typography>
          <Typography
            sx={{ textAlign: 'center', mt: 2, color: 'secondary.main' }}
          >
            Vui lòng không thoát ra khi đang nhập file excel
          </Typography>
        </Stack>
      </ModalBase>
    </>
  );
}

export default ModalImportExcel;
