import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import FilterRadios from '~/components/filter/FilterRadios';
import { useSelector } from 'react-redux';
import { useOrderContext } from '~/pages/order/OrderPage';

function Pttt() {
  const { currentHd } = useSelector((state) => state.order);
  const ptttData = useSelector((state) => state.pttt);
  const { handleUpdateCurrentHd } = useOrderContext();

  const handlePtttChange = (id) => {
    const val = (ptttData?.data || []).find((item) => item._id === id);
    if (!val) return;
    const tyLeCk = Number(val.kieu_so1 || 0);
    let tyLeCkHd =
      (currentHd?.ty_le_ck_hd || 0) - (currentHd?.exfields?.ty_le_ck_pttt || 0);
    if (tyLeCkHd < 0) {
      tyLeCkHd = 0;
    }
    const dataChange = {
      ht_thanh_toan: val._id,
      ten_ht_thanh_toan: val.ten,
      tk_no: val?.tk_cn,
      ty_le_ck_hd: +tyLeCkHd + tyLeCk,
      exfields: { ...(currentHd?.exfields || {}), ty_le_ck_pttt: tyLeCk },
    };
    handleUpdateCurrentHd(dataChange);
  };

  const pttts = useMemo(() => {
    if (!ptttData?.data) {
      return [];
    }
    const onlines = ptttData?.data.reduce((acc, item) => {
      if (item.hinh_thuc === 1 || item.hinh_thuc === 2) {
        return [...acc, { label: item.ten, value: item._id, ...item }];
      } else {
        return acc;
      }
    }, []);
    const offlines = ptttData?.data.reduce((acc, item) => {
      if (item.hinh_thuc === 0 || item.hinh_thuc === 2) {
        return [...acc, { label: item.ten, value: item._id, ...item }];
      } else {
        return acc;
      }
    }, []);
    return {
      onlines,
      offlines,
    };
  }, [ptttData?.data]);

  return (
    <Box sx={{ padding: '0 2px' }}>
      {currentHd?.is_website ? (
        <FilterRadios
          title="Hình thức thanh toán"
          values={pttts.onlines}
          showActive={false}
          defaultValue={currentHd?.ht_thanh_toan || ''}
          onChange={handlePtttChange}
        />
      ) : (
        <FilterRadios
          title="Hình thức thanh toán"
          values={pttts.offlines}
          showActive={false}
          defaultValue={currentHd?.ht_thanh_toan || ''}
          onChange={handlePtttChange}
        />
      )}
    </Box>
  );
}

export default Pttt;
