import { createSlice } from '@reduxjs/toolkit';

const nghiepvuSlice = createSlice({
  name: 'nghiepvu',
  initialState: {
    data: [],
  },
  reducers: {
    updateNghiepvu(state, action) {
      state.data = action.payload;
    },
  },
});

export const { updateNghiepvu } = nghiepvuSlice.actions;
export default nghiepvuSlice.reducer;
