import React, { useEffect, useState } from 'react';
import { Box, Chip, Grid, Stack, Typography } from '@mui/material';
import TextInput from '../input/TextInput';
import { LABEL_KHO } from '~/utils/label.constant';
import DatetimeInput from '../input/DatetimeInput';
import moment from 'moment';
import TableDisplay from '../table/TableDisplay';
import ButtonBase from '../button/ButtonBase';
import useAlertContext from '~/hooks/hookContext/useAlertContext';
import useApisContext from '~/hooks/hookContext/useApisContext';
import useConfirmContext from '~/hooks/hookContext/useConfirmContext';
import { formatDateDisplay, numeralCustom } from '~/utils/helpers';
import { IoMdTrash } from 'react-icons/io';
import useAuthorize from '~/hooks/useAuthorize';

function ExpandHd3({ data, setLoad, openForm }) {
  const allowAuthorize = useAuthorize();
  const showAlert = useAlertContext();
  const showConfirm = useConfirmContext();
  const { asyncSearchList, asyncDelete } = useApisContext();
  const [hd2, setHd2] = useState();

  const findHd2 = async (id) => {
    try {
      const resp = await asyncSearchList({
        apiCode: 'hd2',
        condition: { q: { _id: id } },
      });
      if (resp && Array.isArray(resp) && resp.length > 0) {
        setHd2(resp[0]);
      }
    } catch (error) {
      showAlert({
        type: 'error',
        message: error?.message || 'Something went wrong!',
      });
    }
  };

  const handleDelete = async () => {
    try {
      const resp = await asyncDelete({ apiCode: 'pn1', uniqueValue: data._id });
      if (!resp?.error) {
        setLoad((prev) => prev + 1);
      }
    } catch (error) {
      showAlert({
        type: 'error',
        message: error?.message || 'Something went wrong',
      });
    }
  };

  useEffect(() => {
    if (data?.details?.length > 0) {
      findHd2(data?.details?.[0]?.id_hd);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        padding: '20px',
        backgroundColor: 'whitish.graySoft',
        border: '1px solid',
        borderColor: 'primary.main',
        borderRadius: '0 0 4px 4px',
        position: 'relative',
        zIndex: 0,
      }}
    >
      <Grid container spacing="20px">
        <Grid item xs={12} md={5}>
          <Stack spacing="20px">
            <TextInput
              readOnly
              labelWidth="40%"
              label="Số chứng từ"
              value={data.so_ct}
            />
            <TextInput
              readOnly
              labelWidth="40%"
              label={LABEL_KHO}
              value={data.ma_kho}
            />
            <TextInput
              readOnly
              labelWidth="40%"
              label="Khách hàng"
              value={data.ten_kh}
            />
            <TextInput
              readOnly
              labelWidth="40%"
              label="Hóa đơn trả"
              value={
                !!hd2
                  ? `Chứng từ : ${hd2?.so_ct} - ${formatDateDisplay(
                      hd2?.ngay_ct,
                      'DD/MM/YYYY HH:mm'
                    )}`
                  : null
              }
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={7}>
          <Stack spacing="20px">
            <DatetimeInput
              labelWidth="40%"
              label="Ngày chứng từ"
              readOnly
              value={moment(data.ngay_ct).format('YYYY-MM-DD HH:mm')}
            />
            <DatetimeInput
              labelWidth="40%"
              label="Ngày tạo"
              readOnly
              value={moment(data.date_created).format('YYYY-MM-DD HH:mm')}
            />
            <TextInput
              readOnly
              labelWidth="40%"
              label="Người tạo"
              value={data.user_created}
            />
            <DatetimeInput
              labelWidth="40%"
              label="Ngày cập nhật cuối"
              readOnly
              value={moment(data.date_updated).format('YYYY-MM-DD HH:mm')}
            />
            <TextInput
              readOnly
              labelWidth="40%"
              label="Người cập nhật cuối"
              value={data.user_updated}
            />
          </Stack>
        </Grid>
      </Grid>
      <Stack
        direction="row"
        alignItems="flex-start"
        spacing="10px"
        sx={{ marginTop: '20px' }}
      >
        <Typography sx={{ fontWeight: 600 }}>Ghi chú:</Typography>
        <Typography>{data.dien_giai}</Typography>
      </Stack>
      {data?.details?.length > 0 && (
        <Stack spacing="5px" sx={{ marginTop: '20px' }}>
          <Typography sx={{ fontWeight: 600 }}>Chi tiết trả hàng:</Typography>
          <TableDisplay
            columns={[
              {
                name: 'Hàng hóa',
                selector: (row) => `${row.ten_vt} (${row.ma_vt})`,
                cell: (row) => {
                  return (
                    <Typography>
                      {row.ten_vt} ({row.ma_vt})
                      {row.ma_lo && (
                        <Chip
                          sx={{
                            backgroundColor: 'secondary.main',
                            color: 'whitish.pureWhite',
                          }}
                          label={row.ma_lo}
                          size="small"
                          component="span"
                        />
                      )}
                    </Typography>
                  );
                },
                minWidth: '200px',
                wrap: true,
              },
              {
                name: 'Số lượng',
                selector: (row) => row.sl_nhap,
                width: '80px',
                center: true,
              },
              {
                name: 'Đơn vị tính',
                selector: (row) => row.ma_dvt,
                width: '100px',
                wrap: true,
                center: true,
              },
              {
                name: 'Giá bán',
                selector: (row) => row.gia_ban,
                format: (row) => numeralCustom(row.gia_ban).format(),
                wrap: true,
                width: '120px',
                center: true,
              },
              {
                name: 'Tiền hàng',
                selector: (row) => row.tien,
                format: (row) => numeralCustom(row.tien).format(),
                width: '120px',
                center: true,
              },
              {
                name: 'Tiền CK',
                selector: (row) => row.tien_ck,
                format: (row) => numeralCustom(row.tien_ck).format(),
                width: '120px',
                center: true,
              },
              {
                name: 'Tiền trả lại',
                selector: (row) => row.tien_nhap,
                format: (row) => numeralCustom(row.tien_nhap).format(),
                width: '120px',
                right: true,
              },
            ]}
            data={data.details || []}
          />
        </Stack>
      )}
      {data?.details?.length > 0 && (
        <Box sx={{ marginTop: '10px' }}>
          <Grid container>
            <Grid item xs={12} md={7}></Grid>
            <Grid item xs={12} md={5}>
              <Stack spacing="10px">
                <TextInput
                  readOnly
                  labelWidth="40%"
                  label="Tổng tiền hàng:"
                  value={numeralCustom(data.t_tien).format()}
                />
                <TextInput
                  readOnly
                  labelWidth="40%"
                  label="Tổng chiết khấu:"
                  value={numeralCustom(data.t_ck).format()}
                />
                <TextInput
                  readOnly
                  labelWidth="40%"
                  label="Tổng tiền trả lại:"
                  value={numeralCustom(data.t_tt).format()}
                />
              </Stack>
            </Grid>
          </Grid>
        </Box>
      )}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing="10px"
        sx={{ marginTop: '20px' }}
      >
        {allowAuthorize({ type: 'update', module: 'hd3' }) && (
          <ButtonBase
            onClick={openForm}
            sx={{
              backgroundColor: 'secondary.main',
              '&:hover': { backgroundColor: 'secondary.main' },
            }}
          >
            Chỉnh sửa
          </ButtonBase>
        )}
        {allowAuthorize({ type: 'delete', module: 'hd3' }) && (
          <ButtonBase
            startIcon={<IoMdTrash size={14} />}
            sx={{
              backgroundColor: 'error.main',
              '&:hover': { backgroundColor: 'error.main' },
            }}
            onClick={() => {
              showConfirm({
                title: 'Xác nhận xóa',
                content: (
                  <Box sx={{ padding: '0 10px' }}>
                    <Typography sx={{ fontSize: '14px', textAlign: 'center' }}>
                      Bạn có chắc muốn xóa dòng này không ?
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '12px',
                        textAlign: 'center',
                        fontStyle: 'italic',
                        color: 'primary.main',
                        marginTop: '10px',
                      }}
                    >
                      Lưu ý: Dữ liễu đã xóa sẽ không thể khôi phục.
                    </Typography>
                  </Box>
                ),
                onConfirm: handleDelete,
              });
            }}
          >
            Xóa
          </ButtonBase>
        )}
      </Stack>
    </Box>
  );
}

export default ExpandHd3;
