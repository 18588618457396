import HomePage from '../pages/home/HomePage';
import ChangePasswordPage from '../pages/changePassword/ChangePasswordPage';
import LoginPage from '../pages/Login/LoginPage';
import VerifyEmailPage from '../pages/register/VerifyEmailPage';
import PrivateRoute from './PrivateRoute';
import RestrictedRoute from './RestrictedRoute';
import ReportPage from '~/pages/report/ReportPage';
import Bill from '~/pages/bill/Bill';
import ProfilePage from '~/pages/profile/ProfilePage';
import TokenPage from '~/pages/devices/Divices';
import List from '~/pages/list/List';
import ListVtPage from '~/pages/list/ListVtPage';
import OrderPage from '~/pages/order/OrderPage';
import ListTinhThanhPage from '~/pages/list/ListTinhThanhPage';
import ListHd2Page from '~/pages/list/ListHd2Page';
import BkHoaDonPage from '~/pages/bkhoadon/BkHoaDonPage';

const restrictedRoutes = [
  {
    id: 'login',
    path: '/login',
    page: (
      <RestrictedRoute>
        <LoginPage />
      </RestrictedRoute>
    ),
  },
  {
    id: 'verify-email',
    path: '/verify-email',
    page: (
      <RestrictedRoute>
        <VerifyEmailPage />
      </RestrictedRoute>
    ),
  },
  {
    id: 'verify-code',
    path: '/verify-code',
    page: (
      <RestrictedRoute>
        <VerifyEmailPage isForgotPassword />
      </RestrictedRoute>
    ),
  },
  {
    id: 'change-password',
    path: '/change-password',
    page: (
      <RestrictedRoute>
        <ChangePasswordPage />
      </RestrictedRoute>
    ),
  },
];
const privateRoutes = [
  {
    id: 'homepage',
    path: '/',
    page: (
      <PrivateRoute>
        <HomePage />
      </PrivateRoute>
    ),
  },
  {
    id: 'bill',
    path: '/bill',
    page: (
      <PrivateRoute>
        <Bill />
      </PrivateRoute>
    ),
  },
  {
    id: 'order',
    path: '/order',
    page: (
      <PrivateRoute>
        <OrderPage />
      </PrivateRoute>
    ),
  },
  {
    id: 'report',
    path: '/report/:report',
    page: (
      <PrivateRoute>
        <ReportPage />
      </PrivateRoute>
    ),
  },
  {
    id: 'profile',
    path: '/profile',
    page: (
      <PrivateRoute>
        <ProfilePage />
      </PrivateRoute>
    ),
  },
  {
    id: 'devices',
    path: '/devices',
    page: (
      <PrivateRoute>
        <TokenPage />
      </PrivateRoute>
    ),
  },
  {
    id: 'password',
    path: '/password',
    page: (
      <PrivateRoute>
        <ChangePasswordPage />
      </PrivateRoute>
    ),
  },
  // {
  //   id: 'app',
  //   path: '/app',
  //   page: (
  //     <PrivateRoute>
  //       <EnterprisePage />
  //     </PrivateRoute>
  //   ),
  // },
  {
    id: 'listcode',
    path: '/list/:apicode',
    page: (
      <PrivateRoute>
        <List />
      </PrivateRoute>
    ),
  },
  {
    id: 'listproduct',
    path: '/list/dmvt',
    page: (
      <PrivateRoute>
        <ListVtPage />
      </PrivateRoute>
    ),
  },
  {
    id: 'listproduct',
    path: '/list/tinhthanh',
    page: (
      <PrivateRoute>
        <ListTinhThanhPage />
      </PrivateRoute>
    ),
  },
  {
    id: 'listhd2',
    path: '/list/hd2',
    page: (
      <PrivateRoute>
        <ListHd2Page />
      </PrivateRoute>
    ),
  },
  {
    id: 'bkhaodon',
    path: '/bkhaodon',
    page: (
      <PrivateRoute>
        <BkHoaDonPage />
      </PrivateRoute>
    ),
  },
];

export { restrictedRoutes, privateRoutes };
