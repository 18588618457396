import { Avatar, Stack, Typography } from '@mui/material';
import ButtonBase from '~/components/button/ButtonBase';
import ModalBase from '~/components/modal/ModalBase';
import ErrorIcon from '~/assets/img/error.png';
import InfoIcon from '~/assets/img/info.png';
import WarningIcon from '~/assets/img/warning.png';
import SuccessIcon from '~/assets/img/success.png';

const { createContext, useState } = require('react');

export const AlertContext = createContext();

function AlertProvider({ children }) {
  const [alertOptions, setAlertOptions] = useState({
    open: false,
    type: 'error',
    message: 'Something went wrong!',
  });

  const showAlert = ({ type = 'error', message = 'Something went wrong!' }) => {
    setAlertOptions({ open: true, type, message });
  };

  const handleClose = () => {
    setAlertOptions({
      ...alertOptions,
      open: false,
    });
  };

  const renderIconByType = (type) => {
    switch (type) {
      case 'success':
        return (
          <Avatar src={SuccessIcon} sx={{ width: '60px', height: '60px' }} />
        );
      case 'info':
        return <Avatar src={InfoIcon} sx={{ width: '60px', height: '60px' }} />;
      case 'warning':
        return (
          <Avatar src={WarningIcon} sx={{ width: '60px', height: '60px' }} />
        );
      default:
        return (
          <Avatar src={ErrorIcon} sx={{ width: '60px', height: '60px' }} />
        );
    }
  };

  return (
    <AlertContext.Provider value={showAlert}>
      <ModalBase
        open={alertOptions.open}
        handleClose={handleClose}
        title="Thông báo"
        actions={[
          <ButtonBase key="0" onClick={handleClose}>
            Đã hiểu
          </ButtonBase>,
        ]}
      >
        <Stack
          sx={{ width: '80vw', maxWidth: '400px' }}
          spacing="10px"
          alignItems="center"
        >
          {renderIconByType(alertOptions.type)}
          <Typography
            sx={{
              textAlign: 'center',
              fontSize: '14px',
              lineBreak: 'anywhere',
            }}
          >
            {alertOptions.message}
          </Typography>
        </Stack>
      </ModalBase>
      {children}
    </AlertContext.Provider>
  );
}
export default AlertProvider;
