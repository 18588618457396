import React, { useState, useRef } from 'react';
import {
  TextField,
  Typography,
  Stack,
  Autocomplete,
  ListItem,
  Avatar,
  Skeleton,
  Button,
  Chip,
} from '@mui/material';
import useApisContext from '~/hooks/hookContext/useApisContext';
import useAlertContext from '~/hooks/hookContext/useAlertContext';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { CiSearch } from 'react-icons/ci';
import { numeralCustom } from '~/utils/helpers';
import useLinkImage from '~/hooks/useLinkImage';
import useToken from '~/hooks/useToken';
import ProductImage from '~/assets/img/product.png';
import { VscLoading } from 'react-icons/vsc';
import useBackdropContext from '~/hooks/hookContext/useBackdropContext';
import { useSelector } from 'react-redux';
import ModalBase from '../modal/ModalBase';
import { cloneDeep } from 'lodash';

const emptyValue = [0, '', null];

function SearchAndSelectInput({ onSelect = () => {}, listBoxSx = {} }) {
  const showAlert = useAlertContext();
  const token = useToken();
  const { currentStore } = useSelector((state) => state.store);
  const { currentHd, customer } = useSelector((state) => state.order);
  const [, setBackdrop] = useBackdropContext();
  const { asyncSearchList } = useApisContext();
  const generateLinkImage = useLinkImage(token);
  const [searchText, setSearchText] = useState('');
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const timerRef = useRef();

  const handleCloseModalDvt = () => setCurrentProduct(null);

  const handleSelectProduct = async (selectedProduct) => {
    try {
      const productToAdd = cloneDeep(selectedProduct);
      if (!productToAdd) return;
      setBackdrop(true);

      // const compareDate = moment(currentHd?.ngay_ct)
      //   .hours(0)
      //   .minutes(0)
      //   .seconds(0)
      //   .milliseconds(0);

      // const resp = await asyncSearchList({
      //   apiCode: 'dmgiaban',
      //   condition: {
      //     page: 1,
      //     limit: 99999,
      //     q: {
      //       $and: [
      //         { status: true },
      //         {
      //           $or: [
      //             { ma_kho: currentStore?.ma_kho || '' },
      //             { ma_kho: { $in: emptyValue } },
      //           ],
      //         },
      //         { ma_vt: productToAdd?.ma_vt },
      //         {
      //           hieu_luc_tu: { $lte: compareDate },
      //           hieu_luc_den: { $gte: compareDate },
      //         },
      //         {
      //           $or: [
      //             {
      //               ma_kh: customer?.ma_kh || '',
      //               nh_kh: customer?.nh_kh || '',
      //             },
      //             { ma_kh: { $in: emptyValue }, nh_kh: { $in: emptyValue } },
      //             { ma_kh: customer?.ma_kh || '' },
      //             { nh_kh: customer?.nh_kh || '' },
      //           ],
      //         },
      //       ],
      //     },
      //   },
      // });
      // if (resp && isArray(resp) && resp.length > 0) {
      //   productToAdd.exfields = { dmgiaban: resp };
      // }
      onSelect(productToAdd);
    } catch (error) {
      showAlert({
        type: 'error',
        message: error?.message || 'Something went wrong!',
      });
    } finally {
      setBackdrop(false);
    }
  };

  // handle get data
  const handleGetData = async (searchValue, page, prevOptions) => {
    try {
      if (loading) return;
      setLoading(true);
      const selfCondition = { page, limit: 10, q: {} };
      if (searchValue || searchValue === '') {
        selfCondition.q.$or = [
          {
            ma_vt: {
              $regex: searchValue.split(' ').join('.*'),
              $options: 'i',
            },
          },
          {
            ten_vt: {
              $regex: searchValue.split(' ').join('.*'),
              $options: 'i',
            },
          },
        ];
      }
      const resp = await asyncSearchList({
        apiCode: 'dmvt',
        condition: selfCondition,
      });
      const respCount = await asyncSearchList({
        apiCode: 'dmvt',
        condition: { ...selfCondition, count: 1 },
      });
      setCount(respCount?.rows_number || 0);
      if (resp) {
        setOptions([...prevOptions, ...resp]);
      }
    } catch (error) {
      showAlert({
        type: 'error',
        message: error?.message || 'Something went wrong!',
      });
    } finally {
      setLoading(false);
      setPage(page + 1);
    }
  };

  const hasNextPage = options.length < count;

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: () => handleGetData(search, page, options),
  });

  const handleReset = () => {
    setSearchText('');
    setOptions([]);
    setPage(1);
    setCount(0);
  };

  const handleResetWhenSearchChange = () => {
    setPage(1);
    setCount(0);
  };

  const renderLoading = (sentryRef, key) => {
    return (
      <Stack
        key={key}
        ref={sentryRef}
        direction="row"
        alignItems="flex-start"
        justifyContent="flex-start"
        spacing="10px"
        sx={{ width: '100%', px: 2, py: 1 }}
      >
        <Skeleton
          sx={{ width: '30px', height: '30px' }}
          variant="circular"
          animation="wave"
        />
        <Stack spacing="5px" sx={{ width: '60%' }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ width: '100%' }}
          >
            <Skeleton
              sx={{ width: '38%', height: '14px' }}
              variant="rounded"
              animation="wave"
            />
            <Skeleton
              sx={{ width: '58%', height: '14px' }}
              variant="rounded"
              animation="wave"
            />
          </Stack>
          <Skeleton
            sx={{ width: '100%', height: '14px' }}
            variant="rounded"
            animation="wave"
          />
        </Stack>
      </Stack>
    );
  };

  React.useEffect(() => {
    if (search === '' || search.length >= 3) {
      handleResetWhenSearchChange();
      handleGetData(search, 1, []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
  React.useEffect(() => {
    timerRef.current = setTimeout(() => {
      setSearch(searchText.trim());
    }, 500);
    return () => {
      clearTimeout(timerRef.current);
    };
  }, [searchText]);

  return (
    <>
      {!!currentProduct && (
        <ModalDvt open={!!currentProduct} onClose={handleCloseModalDvt} />
      )}
      <Autocomplete
        isOptionEqualToValue={(option, value) => true}
        clearOnBlur={true}
        clearIcon={null}
        options={options}
        onBlur={handleReset}
        onChange={(_, newValue) => {
          handleSelectProduct(newValue);
        }}
        inputValue={searchText}
        popupIcon={
          loading ? (
            <VscLoading className="round-loading" size={14} />
          ) : (
            <CiSearch size={20} />
          )
        }
        slotProps={{
          popper: { sx: { marginTop: '4px !important' } },
          popupIndicator: { sx: { transform: 'rotate(0deg)' } },
        }}
        ListboxProps={{
          className: 'custome-scrolly',
          sx: {
            maxHeight: '80vh',
            '& .MuiAutocomplete-option': { fontSize: '12px' },
            ...listBoxSx,
          },
        }}
        noOptionsText={
          <Stack spacing="10px" alignItems="center">
            {loading ? (
              renderLoading()
            ) : (
              <Typography
                sx={{
                  fontSize: '14px',
                  fontStyle: 'italic',
                  textAlign: 'center',
                }}
              >
                Không tìm thấy kết quả
              </Typography>
            )}
          </Stack>
        }
        getOptionLabel={(option) => option.ten_vt || ''}
        filterOptions={(options) => {
          if (hasNextPage) {
            options.push({ id: 'load-more' });
          }
          return options;
        }}
        renderOption={(
          { key, className, ...optionProps },
          option,
          state,
          ownerState
        ) => {
          if (option.id === 'load-more') {
            return renderLoading(sentryRef, '1234');
          }
          const imgUrl = option.picture || option.picture2 || option.picture3;
          return (
            <ListItem
              key={option._id || option.id}
              {...optionProps}
              sx={{
                display: 'block',
                cursor: 'pointer',
                borderBottom: '1px dashed',
                borderColor: 'divider',
                '&:hover': { backgroundColor: 'primary.opacity' },
              }}
            >
              <Stack direction="row" alignItems="flex-start" spacing="10px">
                <Avatar
                  src={imgUrl ? generateLinkImage(imgUrl) : ProductImage}
                  sx={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '2px',
                    border: '1px dashed',
                    borderColor: 'primary.main',
                  }}
                />
                <Stack spacing="5px" sx={{ flex: 1 }}>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      lineHeight: '14px',
                      fontWeight: 500,
                    }}
                  >
                    {ownerState.getOptionLabel(option)}{' '}
                    {option.exfields?.hoa_don && (
                      <Chip
                        component="span"
                        size="small"
                        variant="filled"
                        color="warning"
                        label={`Hóa đơn${
                          option.thue_suat_nk > 0
                            ? ` ${option.thue_suat_nk}%`
                            : ''
                        }`}
                        sx={{ '& .MuiChip-label': { color: 'common.white' } }}
                      />
                    )}
                  </Typography>
                  <Stack direction="row" justifyContent="space-between">
                    <Stack direction="row" alignItems="center" spacing="4px">
                      <Typography
                        sx={{
                          fontSize: '12px',
                          fontWeight: 400,
                          maxWidth: '200px',
                          textWrap: 'wrap',
                        }}
                      >
                        {option.ma_vt || ''}
                      </Typography>
                      <Typography sx={{ fontSize: '12px' }}>|</Typography>
                      <Typography sx={{ fontSize: '12px' }}>
                        Tồn kho: {option.ton00 || 0} {option.ten_dvt}
                      </Typography>
                    </Stack>
                    <Typography
                      sx={{
                        fontSize: '14px',
                        fontWeight: 500,
                        color: 'secondary.main',
                      }}
                    >
                      {numeralCustom(option.gia_ban_le).format()}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </ListItem>
          );
        }}
        sx={{ width: '100%' }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Nhập từ 3 ký tự để tìm theo mã hoặc tên sản phẩm"
            autoComplete="off"
            variant="outlined"
            size="small"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            sx={{
              borderRadius: '4px',
              backgroundColor: 'whitish.pureWhite',
              '&:hover .btn-clear-select-api': {
                display: 'flex',
              },
              '& .MuiInputBase-root': { padding: '0 5px 0 0 !important' },
              '& .MuiFormLabel-root': {
                fontSize: '12px',
                fontWeight: 600,
                color: 'primary.main',
                transform: 'translate(14px, -12px)',
                paddingRight: '5px',
                backgroundColor: 'whitish.pureWhite',
              },
              '& .MuiInputBase-input': {
                fontSize: '14px',
                padding: '8px 10px !important',
                '&:placeholder': {
                  fontSize: '14px',
                },
              },
              '& fieldset': {
                border: 'none',
              },
            }}
            onFocus={() => {
              handleGetData('', 1, []);
            }}
          />
        )}
      />
    </>
  );
}

function ModalDvt({ open, onClose }) {
  return (
    <ModalBase
      open={open}
      handleClose={onClose}
      title="Chọn đơn vị tính"
      width="600px"
      actions={[
        <Stack direction="row" alignItems="center" justifyContent="flex-end">
          <Button variant="contained" sx={{ backgroundColor: 'whitish.gray' }}>
            Đóng
          </Button>
        </Stack>,
      ]}
    ></ModalBase>
  );
}

export default SearchAndSelectInput;
