import { Stack } from '@mui/material';
import React from 'react';
import FilterSearch from '../FilterSearch';
import { useState } from 'react';
import { useEffect } from 'react';
import FilterContainer from '../FilterContainer';
import FilterRadios from '../FilterRadios';
import { HINH_THUC_AP_DUNG } from '~/utils/constants';

function FilterPTTT({ setCondition }) {
  const originFilter = { pttt: '', hinh_thuc: 'all' };
  const [mode, setMode] = useState(1);
  const [filter, setFilter] = useState(originFilter);

  const handleRefreshFilter = () => {
    setFilter(originFilter);
  };

  useEffect(() => {
    let condition = {};
    if (filter.pttt) {
      condition.ten = { $regex: filter.pttt, $options: 'i' };
    }
    switch (filter.hinh_thuc) {
      case 'all':
        break;
      default:
        condition.hinh_thuc = filter.hinh_thuc;
        break;
    }
    if (mode === 1) {
      setCondition(condition);
    } else {
      const entries = Object.entries(condition);
      condition = entries.reduce(
        (acc, item) => {
          acc.$or.push({ [item[0]]: item[1] });
          return acc;
        },
        { $or: [] }
      );
      setCondition(condition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <FilterContainer
      mode={mode}
      setMode={setMode}
      handleRefreshFilter={handleRefreshFilter}
    >
      <Stack sx={{ width: '100%' }} spacing="10px">
        <FilterSearch
          title="Tên phương thức"
          placeholder="Tìm theo tên"
          value={filter.pttt}
          onSearch={(value) => setFilter({ ...filter, pttt: value })}
        />
        <FilterRadios
          title="Hình thức áp dụng"
          values={[{ label: 'Tất cả', value: 'all' }, ...HINH_THUC_AP_DUNG]}
          defaultValue={filter.hinh_thuc}
          onChange={(value) => setFilter({ ...filter, hinh_thuc: value })}
        />
      </Stack>
    </FilterContainer>
  );
}

export default FilterPTTT;
