import React from 'react';
import { Box, Stack } from '@mui/material';
import { ORDER_HEADER_HEIGHT } from '~/utils/constants';
import NoteHD from './NoteHD';
import ProductLine from '~/components/product-line';
import { useSelector } from 'react-redux';
import { useOrderContext } from '../../OrderPage';
import { cloneDeep } from 'lodash';
import { v4 } from 'uuid';
import useApisContext from '~/hooks/hookContext/useApisContext';
import useAlertContext from '~/hooks/hookContext/useAlertContext';
import useBackdropContext from '~/hooks/hookContext/useBackdropContext';

function Left() {
  const showAlert = useAlertContext();
  const [, setBackdrop] = useBackdropContext();
  const { asyncSearchList } = useApisContext();
  const { currentHd } = useSelector((state) => state.order);
  const { handleUpdateCurrentHd, allowSelectLo } = useOrderContext();

  const handleNumberChange = (id, number) => {
    const newNumber = number < 1 ? 1 : number;
    const currentHdClone = cloneDeep(currentHd);
    const index = currentHdClone.details.findIndex((d) => d._id === id);
    if (index < 0) return;
    const detail = currentHdClone.details[index];
    detail.sl_xuat = newNumber;
    detail.tien_ck_nt =
      (((detail.ty_le_ck || 0) * (detail.gia_ban_le || 0)) / 100).toFixed(0) *
      (detail.sl_xuat || 1);

    handleUpdateCurrentHd({ details: currentHdClone.details });
  };

  const handleDvtChange = (id, dvt) => {
    const { ma_dvt, ten_dvt, gia_ban_nt } = dvt;
    const clonedDetails = cloneDeep(currentHd?.details);
    const existed = clonedDetails.find((item) => item._id === id);
    if (!existed) {
      return;
    }
    existed.ma_dvt = ma_dvt;
    existed.ten_dvt = ten_dvt;
    existed.gia_ban_le_goc = gia_ban_nt;
    existed.gia_ban = gia_ban_nt;
    existed.gia_ban_nt = gia_ban_nt;
    handleUpdateCurrentHd({ details: clonedDetails });
  };

  const handleChietKhauChange = (id, { ty_le_ck = 0, tien_ck = 0 }) => {
    const currentHdClone = cloneDeep(currentHd);
    const index = currentHdClone.details.findIndex((d) => d._id === id);
    if (index < 0) return;
    const detail = currentHdClone.details[index];
    detail.ty_le_ck = ty_le_ck;
    detail.tien_ck_nt = tien_ck * detail.sl_xuat;
    handleUpdateCurrentHd({ details: currentHdClone.details });
  };

  const handleLoChange = (id, lo) => {
    const currentHdClone = cloneDeep(currentHd);
    const index = currentHdClone.details.findIndex((d) => d._id === id);
    if (index < 0) return;
    const detail = currentHdClone.details[index];
    detail.ma_lo = lo?.ma_lo;
    detail.ten_lo = lo?.ten_lo;
    detail.han_sd = lo?.han_sd;
    handleUpdateCurrentHd({ details: currentHdClone.details });
  };

  const handleDuplicate = (copiedDetail) => {
    const currentHdClone = cloneDeep(currentHd);
    const index = (currentHdClone.details || []).findIndex(
      (d) => d._id === copiedDetail._id
    );
    const newDetail = { ...copiedDetail, _id: v4() };
    (currentHdClone.details || []).splice(index, 0, newDetail);
    handleUpdateCurrentHd({ details: currentHdClone.details });
  };

  const handleDeleteDetail = (id) => {
    const newDetails = currentHd.details.filter((d) => d._id !== id);
    handleUpdateCurrentHd({ details: newDetails });
  };

  const handleNoteChange = (id, newNote) => {
    const currentHdClone = cloneDeep(currentHd);
    const index = currentHdClone.details.findIndex((d) => d._id === id);
    if (index < 0) return;
    const detail = currentHdClone.details[index];
    detail.dien_giai = newNote;

    handleUpdateCurrentHd({ details: currentHdClone.details || [] });
  };
  const handleSelectGiaBan = async (id, giaBan) => {
    const currentHdClone = cloneDeep(currentHd);
    const detail = (currentHdClone?.details || []).find((d) => d._id === id);
    if (detail) {
      const giabans = JSON.parse(detail.ds_tem?.[0] || '[]');
      giabans.forEach((g) => {
        if (g._id === giaBan._id) {
          if (!Object.prototype.hasOwnProperty.call(g, 'selected')) {
            g.selected = true;
            giaBan.selected = true;
          } else {
            g.selected = !g.selected;
            giaBan.selected = g.selected;
          }
        } else {
          g.selected = false;
        }
      });
      detail.ds_tem = [JSON.stringify(giabans)];
      // tính toán lại gia_ban, tien, tien_ck, tien_xuat, ty_le_ck
      if (giaBan.selected === true) {
        detail.gia_ban = giaBan.gia_ban_le || 0;
      } else {
        try {
          setBackdrop(true);
          const resp = await asyncSearchList({
            apiCode: 'dmvt',
            condition: { page: 1, limit: 1, q: { ma_vt: detail.ma_vt } },
          });
          if (resp && resp.length > 0) {
            detail.gia_ban = resp[0]?.gia_ban_le || 0;
          }
        } catch (error) {
          showAlert({
            type: 'error',
            message: error.message || 'Something went wrong!',
          });
        } finally {
          setBackdrop(false);
        }
      }
      detail.tien = detail.gia_ban * (detail.sl_xuat || 1);
      detail.tien_xuat = detail.tien;
      detail.tien_ck = 0;
      detail.ty_le_ck = 0;
    }
    handleUpdateCurrentHd({ details: currentHdClone.details || [] });
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: `calc(100vh - ${ORDER_HEADER_HEIGHT} - 10px)`,
        overflow: 'auto',
      }}
    >
      <Stack
        sx={{
          width: '100%',
          height: `calc(100vh - ${ORDER_HEADER_HEIGHT} - 10px - 40px - 10px)`,
          overflow: 'auto',
          padding: '5px',
        }}
        spacing="10px"
      >
        {!!currentHd?.details &&
          currentHd.details.length > 0 &&
          currentHd.details.map((detail, index) => (
            <ProductLine
              key={detail._id}
              stt={index + 1}
              data={detail}
              allowSelectLo={allowSelectLo}
              ngayCt={currentHd?.ngay_ct}
              onDelete={handleDeleteDetail}
              onNumberChange={handleNumberChange}
              onChietKhauChange={handleChietKhauChange}
              onLoChange={handleLoChange}
              onDuplicate={handleDuplicate}
              onNoteChange={handleNoteChange}
              onSelectGiaBan={handleSelectGiaBan}
              onDvtChange={handleDvtChange}
            />
          ))}
      </Stack>
      <NoteHD />
    </Box>
  );
}

export default Left;
