import React from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import TextInput from '../input/TextInput';
import { LABEL_KHO } from '~/utils/label.constant';
import { numeralCustom } from '~/utils/helpers';
import DatetimeInput from '../input/DatetimeInput';
import moment from 'moment';
import ButtonBase from '../button/ButtonBase';
import useAlertContext from '~/hooks/hookContext/useAlertContext';
import useApisContext from '~/hooks/hookContext/useApisContext';
import useConfirmContext from '~/hooks/hookContext/useConfirmContext';
import useAuthorize from '~/hooks/useAuthorize';

function ExpandPT({ data, openForm, setLoad }) {
  const allowAuthorize = useAuthorize();
  const showAlert = useAlertContext();
  const showConfirm = useConfirmContext();
  const { asyncDelete } = useApisContext();

  const handleDelete = async () => {
    try {
      const resp = await asyncDelete({ apiCode: 'pt1', uniqueValue: data._id });
      if (!resp?.error) {
        setLoad((prev) => prev + 1);
      }
    } catch (error) {
      showAlert({
        type: 'error',
        message: error?.message || 'Something went wrong',
      });
    }
  };

  return (
    <Box
      sx={{
        padding: '20px',
        backgroundColor: 'whitish.graySoft',
        border: '1px solid',
        borderColor: 'primary.main',
        borderRadius: '0 0 4px 4px',
        position: 'relative',
        zIndex: 0,
      }}
    >
      <Grid container spacing="20px">
        <Grid item xs={12} md={5}>
          <Stack spacing="20px">
            <TextInput
              readOnly
              labelWidth="40%"
              label="Số chứng từ"
              value={data.so_ct}
            />
            <TextInput
              readOnly
              labelWidth="40%"
              label={LABEL_KHO}
              value={data.ten_kho}
            />
            <TextInput
              readOnly
              labelWidth="40%"
              label="Người nộp"
              value={data.ten_kh}
            />
            <TextInput
              readOnly
              labelWidth="40%"
              label="Giá trị"
              value={numeralCustom(data.t_tt_nt).format()}
            />
            <TextInput
              readOnly
              labelWidth="40%"
              label="Nghiệp vụ"
              value={data?.exfields?.nghiep_vu?.ten_nghiep_vu}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={7}>
          <Stack spacing="20px">
            <DatetimeInput
              labelWidth="40%"
              label="Ngày chứng từ"
              readOnly
              value={moment(data.ngay_ct).format('YYYY-MM-DD HH:mm')}
            />
            <DatetimeInput
              labelWidth="40%"
              label="Ngày tạo"
              readOnly
              value={moment(data.date_created).format('YYYY-MM-DD HH:mm')}
            />
            <TextInput
              readOnly
              labelWidth="40%"
              label="Người tạo"
              value={data.user_created}
            />
            <DatetimeInput
              labelWidth="40%"
              label="Ngày cập nhật cuối"
              readOnly
              value={moment(data.date_updated).format('YYYY-MM-DD HH:mm')}
            />
            <TextInput
              readOnly
              labelWidth="40%"
              label="Người cập nhật cuối"
              value={data.user_updated}
            />
          </Stack>
        </Grid>
      </Grid>
      <Stack
        direction="row"
        alignItems="flex-start"
        spacing="10px"
        sx={{ marginTop: '20px' }}
      >
        <Typography sx={{ fontWeight: 600 }}>Ghi chú:</Typography>
        <Typography>{data.dien_giai}</Typography>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing="10px"
        sx={{ marginTop: '20px' }}
      >
        {allowAuthorize({ type: 'update', module: 'pt1' }) && (
          <ButtonBase
            sx={{
              backgroundColor: 'secondary.main',
              '&:hover': { backgroundColor: 'secondary.main' },
            }}
            onClick={openForm}
          >
            Chỉnh sửa
          </ButtonBase>
        )}
        {allowAuthorize({ type: 'delete', module: 'pt1' }) && (
          <ButtonBase
            sx={{
              backgroundColor: 'error.main',
              '&:hover': { backgroundColor: 'error.main' },
            }}
            onClick={() => {
              showConfirm({
                title: 'Xác nhận xóa',
                content: (
                  <Box sx={{ padding: '0 10px' }}>
                    <Typography sx={{ fontSize: '14px', textAlign: 'center' }}>
                      Bạn có chắc muốn xóa dòng này không ?
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '12px',
                        textAlign: 'center',
                        fontStyle: 'italic',
                        color: 'primary.main',
                        marginTop: '10px',
                      }}
                    >
                      Lưu ý: Dữ liễu đã xóa sẽ không thể khôi phục.
                    </Typography>
                  </Box>
                ),
                onConfirm: handleDelete,
              });
            }}
          >
            Xóa
          </ButtonBase>
        )}
      </Stack>
    </Box>
  );
}

export default ExpandPT;
