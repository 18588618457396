import React, { useEffect, useState } from 'react';
import {
  Box,
  Chip,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import InputPrice from './InputPrice';
import ChangeNumber from './ChangeNumber';
import Price from './Price';
import SelectLo from './SelectLo';
import MenuHover from '../menu/MenuHover';
import { FaRegEye } from 'react-icons/fa';
import {
  BsFillCaretDownFill,
  BsFillCaretRightFill,
  BsTrash,
} from 'react-icons/bs';
import { numeralCustom } from '~/utils/helpers';
import useApisContext from '~/hooks/hookContext/useApisContext';
import useAlertContext from '~/hooks/hookContext/useAlertContext';
import { FiCopy } from 'react-icons/fi';
import DrawerDetail from './DrawerDetail';
import NoteLine from './NoteLine';

function ProductLine({
  data,
  stt = 1,
  allowSelectLo,
  ngayCt,
  onDelete = (_id) => {},
  onNumberChange = (id, number) => {},
  onChietKhauChange = (id, { ty_le_ck, tien_ck }) => {},
  onLoChange = (id, lo) => {},
  onDuplicate = (copiedDetail) => {},
  onNoteChange = (id, note) => {},
  onSelectGiaBan = (id, giaBan) => {},
  onDvtChange = (id, dvt) => {},
}) {
  const showAlert = useAlertContext();
  const { asyncSearchList } = useApisContext();
  const [originProduct, setOriginProduct] = useState();
  const [openDrawerDetail, setOpenDrawerDetail] = useState(false);
  const [showNote, setShowNote] = useState(!!data?.dien_giai);

  // handle copy
  const handleDuplicate = () => {
    onDuplicate(data);
  };
  const getOriginProduct = async () => {
    try {
      const resp = await asyncSearchList({
        apiCode: 'dmvt',
        condition: { page: 1, limit: 1, q: { ma_vt: data?.ma_vt } },
      });
      if (resp && Array.isArray(resp) && resp.length > 0) {
        setOriginProduct(resp[0]);
      }
    } catch (error) {
      showAlert({
        type: 'error',
        message: error?.message || 'Something went wrong!',
      });
    }
  };

  useEffect(() => {
    getOriginProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.ma_vt]);

  return (
    <>
      <DrawerDetail
        detail={data}
        open={openDrawerDetail}
        onClose={() => setOpenDrawerDetail(false)}
        originProduct={originProduct}
        onNumberChange={onNumberChange}
      />
      <Box
        sx={{
          padding: '5px 8px',
          backgroundColor: 'whitish.pureWhite',
          borderRadius: '4px',
        }}
      >
        {/* Top */}
        <Grid container spacing="10px">
          <Grid item xs={9.5}>
            <Stack
              direction="row"
              alignItems="flex-start"
              spacing="5px"
              sx={{ height: '100%' }}
            >
              <Tooltip
                placement="top"
                title={showNote ? 'Đóng ghi chú' : 'Mở ghi chú'}
              >
                <IconButton
                  onClick={() => setShowNote(!showNote)}
                  sx={{ width: '24px', height: '24px', padding: '6px' }}
                >
                  {showNote ? (
                    <BsFillCaretDownFill />
                  ) : (
                    <BsFillCaretRightFill />
                  )}
                </IconButton>
              </Tooltip>
              <Typography>{stt}.</Typography>
              <Typography>{data?.ten_vt} </Typography>
            </Stack>
          </Grid>
          <Grid item xs={2}>
            <Stack direction="row" justifyContent="flex-end">
              <Chip
                variant="outlined"
                label={data?.ten_dvt || data?.ma_dvt}
                color="primary"
              />
            </Stack>
          </Grid>
          <Grid item xs={0.5}>
            <Stack direction="row" justifyContent="flex-end">
              <MenuHover
                content={
                  <Stack>
                    <MenuItem
                      sx={{ columnGap: '10px' }}
                      onClick={() => setOpenDrawerDetail(true)}
                    >
                      <FaRegEye size={14} />
                      <Typography sx={{ fontSize: '14px' }}>
                        Xem chi tiết
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={handleDuplicate}
                      sx={{ columnGap: '10px' }}
                    >
                      <FiCopy size={14} />
                      <Typography sx={{ fontSize: '14px' }}>
                        Nhân bản
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      sx={{ columnGap: '10px', color: 'error.main' }}
                      onClick={() => onDelete(data?._id)}
                    >
                      <BsTrash size={14} />
                      <Typography
                        sx={{ fontSize: '14px', color: 'error.main' }}
                      >
                        Xóa bỏ
                      </Typography>
                    </MenuItem>
                  </Stack>
                }
              >
                <IconButton size="small">
                  <BiDotsVerticalRounded size={16} />
                </IconButton>
              </MenuHover>
            </Stack>
          </Grid>
        </Grid>
        {/* Middle */}
        <Grid container spacing="10px" mt={0.5}>
          <Grid item xs={3}>
            {!!originProduct && originProduct.ma_lo_yn && allowSelectLo && (
              <SelectLo
                detail={data}
                onLoChange={(lo) => onLoChange(data?._id, lo)}
              />
            )}
          </Grid>
          <Grid item xs={3}>
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{ width: '100%', height: '100%' }}
            >
              <Price
                detail={data}
                onChietKhauChange={(ck) => onChietKhauChange(data._id, ck)}
              />
            </Stack>
          </Grid>
          <Grid item xs={3}>
            <Stack
              sx={{ width: '100%', height: '100%' }}
              alignItems="center"
              justifyContent="center"
              direction="row"
            >
              <ChangeNumber
                detail={data}
                onNumberChange={(number) => onNumberChange(data._id, number)}
              />
            </Stack>
          </Grid>
          <Grid item xs={3}>
            <Stack alignItems="center" sx={{ width: '100%', height: '100%' }}>
              <InputPrice
                readOnly
                width="100px"
                value={numeralCustom(data?.tien_xuat_nt || 0).format()}
                onChange={() => {}}
              />
            </Stack>
          </Grid>
        </Grid>
        {/* Note */}
        <NoteLine
          show={showNote}
          defaultValue={data?.dien_giai}
          ngayCt={ngayCt}
          onNoteChange={(val) => onNoteChange(data?._id, val)}
        />
      </Box>
    </>
  );
}

export default ProductLine;
