import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import AdminLayout from '~/components/layouts/AdminLayout';
import useApisContext from '~/hooks/hookContext/useApisContext';
import { isArray } from 'lodash';
import useAlertContext from '~/hooks/hookContext/useAlertContext';
import moment from 'moment';
import TableDisplay from '~/components/table/TableDisplay';

const columns = [
  {
    name: 'Mã hàng',
    selector: (row) => row.ma_vt,
  },
  {
    name: 'Tên hàng',
    selector: (row) => row.ten_vt,
  },
  {
    name: 'Đơn vị tính',
    selector: (row) => row.ma_dvt,
  },
];

function BkHoaDonPage() {
  const showAlert = useAlertContext();
  const { asyncGetReport } = useApisContext();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const getReport = async () => {
    try {
      const today = moment();
      setLoading(true);
      const resp = await asyncGetReport({
        apiCode: 'ctbanle',
        queryObject: {
          tu_ngay: today.clone().startOf('years').toISOString(),
          den_ngay: today.clone().toISOString(),
        },
      });
      if (isArray(resp)) {
        resp.pop();
        setData(resp);
      } else {
        showAlert({
          type: 'error',
          message: resp?.message || resp?.error || 'Lỗi khi tải báo cáo',
        });
      }
    } catch (error) {
      showAlert({
        type: 'error',
        message: error?.message || error?.error || 'Lỗi khi tải báo cáo',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getReport();
  }, []);

  return (
    <AdminLayout>
      <Box sx={{ py: 2 }}>
        <Typography variant="h1">Bảng kê hóa đơn bán hàng</Typography>
        <TableDisplay
          data={data}
          columns={columns}
          fixedHeaderScrollHeight="calc(100vh - 50px - 42px - 32px - 42px)"
        />
      </Box>
    </AdminLayout>
  );
}

export default BkHoaDonPage;
