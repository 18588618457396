import React from 'react';
import { Box, Grid } from '@mui/material';
import CollapseSection from '~/components/collapse/CollapseSection';
import TextInput from '~/components/input/TextInput';

function AccoutnSection({ show, setCollapses, register }) {
  return (
    <CollapseSection
      title="Thông tin tài khoản"
      show={show}
      onCollapse={() =>
        setCollapses((prev) => ({ ...prev, account: !prev.account }))
      }
    >
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextInput
              labelWidth="30%"
              label="TK ngân hàng"
              placeholder="3829981"
              name="tai_khoan_nh"
              register={register}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              labelWidth="30%"
              label="Chủ tài khoản"
              placeholder="Lê Kim Nhàn"
              name="chu_tai_khoan"
              register={register}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              labelWidth="30%"
              label="Ngân hàng"
              placeholder="BIDV"
              name="ngan_hang"
              register={register}
            />
          </Grid>
        </Grid>
      </Box>
    </CollapseSection>
  );
}

export default AccoutnSection;
