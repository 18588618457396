import React from 'react';
import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import CollapseSection from '~/components/collapse/CollapseSection';
import TextInput from '~/components/input/TextInput';
import { Controller } from 'react-hook-form';
import { numeralCustom } from '~/utils/helpers';
import { HINH_THUC_AP_DUNG } from '~/utils/constants';

function InfoSection({ register, errors, isEdit, control }) {
  return (
    <CollapseSection show title="Thông tin chung">
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextInput
              labelWidth="30%"
              label="Tên PT"
              placeholder="Thanh toán khi nhận hàng"
              name="ten"
              register={register}
              required
              errorMessage={errors?.ten?.message}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="kieu_so1"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <TextInput
                    type="number"
                    label="Tỷ lệ CK"
                    labelWidth="30%"
                    inputProps={{ min: 0 }}
                    value={numeralCustom(value).format()}
                    onChange={onChange}
                  />
                );
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Stack direction="row" alignItems="center" gap={2}>
              <Typography sx={{ fontWeight: 600 }}>
                Hình thức áp dụng
              </Typography>
              <Controller
                name="hinh_thuc"
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <RadioGroup
                      row
                      defaultValue={2}
                      value={Number(value)}
                      onChange={(_, val) => {
                        onChange(Number(val));
                      }}
                    >
                      {HINH_THUC_AP_DUNG.map((item) => {
                        return (
                          <FormControlLabel
                            key={item.value}
                            label={item.label}
                            value={item.value}
                            control={<Radio />}
                          />
                        );
                      })}
                    </RadioGroup>
                  );
                }}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </CollapseSection>
  );
}

export default InfoSection;
